<script lang="ts" setup>
import type { NetSavingsTrendMonth } from '@azure/services/savings.models';

import _ from 'lodash';
import moment from 'moment';
import { computed } from 'vue';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps<{
  netSavingsTrend: NetSavingsTrendMonth[];
}>();

const xAxis = computed(() => {
  const categories = props.netSavingsTrend.map(item => moment.utc(item.month_key).format('MMM YYYY'));
  return {
    categories,
  };
});

const yAxis = computed(() => {
  const esr = _.compact(_.map(props.netSavingsTrend, 'effective_savings_rate_percentage'));
  const base = _.compact(_.map(props.netSavingsTrend, 'effective_savings_rate_baseline_percentage'));
  const smallestValue = _.min([...esr, ...base]) ?? 0;
  const min = smallestValue >= 0 ? 0 : _.floor(smallestValue, -1);
  const rightTickPositions = _.range(min, 61, 10);

  return [
    {
      tickAmount: rightTickPositions.length,
      title: {
        text: null,
      },
      labels: {
        format: getCurrencyFormat(),
      },
    },
    {
      tickPositions: rightTickPositions,
      title: {
        text: null,
      },
      labels: {
        format: percentFormat,
      },
      opposite: true,
    },
  ];
});

const series = computed(() => {
  const firstMonths = _.initial(props.netSavingsTrend);
  const lastMonth = _.last(props.netSavingsTrend);
  const includeLastMonth = _.some(
    [
      'compute_usage_normalized',
      'net_savings_normalized',
      'effective_savings_rate_percentage',
      'effective_savings_rate_baseline_percentage',
    ],
    prop => _.has(lastMonth, prop)
  );

  const data = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
  const computeUsage = _.map(data, x => _.get(x, 'compute_usage_normalized', null));
  const netSavings = _.map(data, x => _.get(x, 'net_savings_normalized', null));
  const esr = _.map(data, x => _.get(x, 'effective_savings_rate_percentage', null));
  const base = _.map(data, x => _.get(x, 'effective_savings_rate_baseline_percentage', null));

  return [
    {
      label: 'Compute Usage',
      name: 'Compute Usage',
      data: computeUsage,
      color: '#a7a3ff',
      dashStyle: 'solid',
      type: 'column',
      yAxis: 0,
      tooltip: {
        valuePrefix: '$',
        valueSuffix: '',
        valueDecimals: 2,
      },
      legendOrder: 0,
      legendSelected: true,
    },
    {
      label: 'Net Savings',
      name: 'Net Savings',
      data: netSavings,
      color: '#00c58c',
      dashStyle: 'solid',
      type: 'column',
      yAxis: 0,
      tooltip: {
        valuePrefix: '$',
        valueSuffix: '',
        valueDecimals: 2,
      },
      legendOrder: 1,
      legendSelected: true,
    },
    {
      label: 'Effective Savings Rate with ProsperOps',
      name: 'Effective Savings Rate with ProsperOps',
      data: esr,
      type: 'line',
      color: '#fcbe2c',
      dashStyle: 'solid',
      yAxis: 1,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: '',
        valueSuffix: '%',
        valueDecimals: 1,
      },
      legendOrder: 2,
      legendSelected: true,
    },
    {
      label: 'Effective Savings Rate before ProsperOps (baseline)',
      name: 'Effective Savings Rate before ProsperOps (baseline)',
      data: base,
      type: 'line',
      color: '#495057',
      dashStyle: 'dash',
      yAxis: 1,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: '',
        valueSuffix: '%',
        valueDecimals: 1,
      },
      legendOrder: 3,
      legendSelected: true,
    },
  ].filter(Boolean);
});

const filteredSeries = computed(() => {
  return _.filter(series.value, s => s.legendSelected);
});
</script>

<template>
  <PanelSection header="Net Savings Trend">
    <div>
      <ChartLegend v-model="series" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
      <div class="d-flex flex-row-reverse">
        <small>(normalized)</small>
      </div>
    </div>
  </PanelSection>
</template>
