<script setup lang="ts">
import type { Tenant } from '@azure/services/subscriptions';

import _ from 'lodash';
import { computed, ref } from 'vue';

import AzureTenantList from './AzureTenantList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import Pagination from '@shared/design/Pagination.vue';

const currentPageIndex = ref(1);
const itemsPerPage = ref(10);

const props = defineProps<{
  validateTenant: (tenant: Tenant) => void;
  validateAll: () => void;
  tenants: Tenant[];
}>();

const currentPage = computed(() => {
  const totalCount = props.tenants.length;
  const pageStart = (currentPageIndex.value - 1) * itemsPerPage.value;
  const pageEnd = Math.min(pageStart + itemsPerPage.value, totalCount);
  return props.tenants.slice(pageStart, pageEnd);
});

const anyNeedsValidated = () => _.some(props.tenants, { validated: false });
</script>
<template>
  <div class="row mb-3">
    <div class="col">
      <h2>Additional Azure Tenant Onboarding</h2>
      <div class="pt-2">
        <BoxMessage v-if="anyNeedsValidated()" type="warning">
          ProsperOps has found additional Azure Tenants that require action. Click each tenant ID to view instructions
          and complete validation steps.</BoxMessage
        >
        <BoxMessage v-else type="success"> All Azure Tenants have been validated.</BoxMessage>
      </div>
    </div>
  </div>
  <div v-if="anyNeedsValidated()" class="row justify-content-end">
    <BaseButton variant="primary" size="sm" class="mr-3 mb-3 rounded-sm" @click="validateAll">
      Validate All Pending
    </BaseButton>
  </div>
  <AzureTenantList :validate-tenant="validateTenant" :validate-all="validateAll" :tenants="currentPage" />
  <Pagination v-model="currentPageIndex" :total-rows="tenants.length" :per-page="itemsPerPage" class="mt-3" />
</template>
