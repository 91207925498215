<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ref } from 'vue';

interface TimePickerOption {
  value: number;
  label: string;
}
interface TimeFrame {
  value: number;
  label: string;
}
type CloseFunction = () => void;

const props = defineProps<{
  options: TimePickerOption[];
  defaultOptionIndex: number;
}>();

const emits = defineEmits<{ (e: 'onChange', option: TimeFrame): void }>();

// Always use the first option as selected default
const selectedOption = ref(props.options[props.defaultOptionIndex]);
const selectedOptionText = ref(`Last ${selectedOption.value.label}`);

const selectTimeframe = (option: TimeFrame, close: CloseFunction) => {
  selectedOption.value = option;
  selectedOptionText.value = `Last ${option.label}`;

  // Push the selected option to the parent component
  emits('onChange', option);
  close();
};
</script>

<template>
  <div class="timepicker d-flex align-items-center">
    <div class="timePickerContainer">
      <Popover>
        <PopoverButton id="timepickerButton">
          <div class="timeIcon">
            <BaseIcon name="clock" class="mr-1 text-white" />
          </div>
          <div class="selectedText text-white">{{ selectedOptionText }}</div>
          <div>
            <BaseIcon name="chevron-down" class="mr-1 text-white chevronDown" />
          </div>
        </PopoverButton>
        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel v-slot="{ close }" class="popoverPanel">
            <div class="popoverContent">
              <div class="contentButtonGroup">
                <template v-for="(option, index) in props.options" :key="index">
                  <button
                    class="contentItemButton"
                    @click="() => selectTimeframe({ value: option.value, label: option.label }, close)"
                  >
                    <div class="itemText" :class="{ active: selectedOption.value === option.value }">
                      {{ option.label }}
                    </div>
                  </button>
                </template>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.timepicker {
  .timePickerContainer {
    position: relative;
  }

  .popoverPanel {
    position: absolute;
    right: 0;
    z-index: 5;

    .popoverContent {
      background-color: $white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

      .contentButtonGroup {
        display: flex;
        flex: 1;
        flex-direction: column;

        .timepickerDivider {
          padding: 1px 0;
          margin: 0;
          border-bottom: 1px solid #e7e9ea;
        }

        .contentItemButton {
          width: 100%;
          min-width: 125px;
          padding: 6px 24px;
          color: $gray-900;
          text-align: right;
          cursor: pointer;
          background: none;
          border: none;
          outline: none;
        }

        .contentItemButton:hover {
          background-color: $gray-200;
        }

        .itemText {
          display: inline-block;
          width: auto;
          padding: 1px 5px;
        }
      }
    }
  }
}

::v-deep(#timepickerButton) {
  display: flex;
  flex-direction: row;
  min-width: 175px;
  padding: 5px;
  background-color: map-get($theme-colors, 'primary');
  border: none;
  border-radius: 0.2rem;
  box-shadow: none;

  .timeIcon {
    margin-right: 8px;
    margin-left: 8px;
  }

  .selectedText {
    flex-grow: 2;
    justify-content: center;
  }

  .chevronDown {
    margin-left: 8px;
  }
}

::v-deep #timepickerButton:hover {
  background-color: #4c44ff;
}

.active {
  border-bottom: 3px solid map-get($theme-colors, 'primary');
}
</style>
