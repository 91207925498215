<script lang="ts" setup>
import type { OrganizationBudgetQuarter, OrganizationSettingsSummary } from '@console/services/api.models';
import type { AxiosResponse } from 'axios';

import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';

import BaseSettingsPanel from '@aws/components/adm/settings/compute/BaseSettingsPanel.vue';
import FlexSettingsPanel from '@aws/components/adm/settings/compute/FlexSettingsPanel.vue';
import PrepaymentTable from '@aws/components/adm/settings/compute/PrepaymentTable.vue';
import SavingsPlanLadderSettingsPanel from '@aws/components/adm/settings/compute/SavingsPlanLadderSettingsPanel.vue';
import RequiresRole from '@console/components/RequiresRole.vue';
import SettingDescription from '@console/components/settings/SettingDescription.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import CalendlyButton from '@console/components/widgets/CalendlyButton.vue';
import Layout from '@console/Layout.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import StatusPill from '@shared/design/StatusPill.vue';

useHead({
  title: 'Compute Settings',
});

const settings = ref<OrganizationSettingsSummary | null>(null);
const budget = ref<OrganizationBudgetQuarter[]>([]);
const loading = ref(true);

const vuexStore = useVuexStore();
const selectedOrganization = computed(() => vuexStore.getters['aws/selectedOrganization']);

const featureStore = useFeatureStore();

onMounted(async () => {
  const awsOrganization = selectedOrganization.value;
  const responses = await Promise.all([
    customerService.getSettingsSummary(awsOrganization.id) as Promise<AxiosResponse<OrganizationSettingsSummary>>,
    customerService.getBudget(awsOrganization.id) as Promise<AxiosResponse<OrganizationBudgetQuarter[]>>,
  ]);
  settings.value = responses[0].data;
  budget.value = responses[1].data;
  loading.value = false;
});

const showPrePayment = computed(() => {
  if (!settings.value) return false;
  if (settings.value.hide_budget) return false;

  const prepaymentPreferences = [
    // Include the Base, Flex, and SPAL prepayment preferences
    settings.value.base_pre_payment_preference,
    settings.value.flex_pre_payment_preference,
    settings.value.adm_compute_sp_ladder_prepayment_preference,

    // Include the Flex regional prepayment preferences
    ...(settings.value.flex_pre_payment_region_preference ?? []).map(r => r.preference),
  ];

  // If any prepayment preference is not 'No Upfront', show the prepayment settings
  return prepaymentPreferences.some(p => p !== 'No Upfront');
});
</script>

<template>
  <Layout :loading="loading">
    <template #default>
      <div v-if="settings" class="settingsContainer">
        <PageHeader>
          <h1>Compute Settings</h1>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <SettingsPanel header="Status">
              <SettingRow class="settingRow">
                <template v-slot:label> Seeding </template>
                <template v-slot:description>
                  Deploy periodic Flex microcommitments in regions with material EC2 spend
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_seeding">
                    <span v-if="settings.primary_function_enable_seeding">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Optimizing Existing Commitment </template>
                <template v-slot:description>
                  Optimization of existing commitment and removal of unutilized commitment
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_optimizing_existing_commitment">
                    <span v-if="settings.primary_function_enable_optimizing_existing_commitment">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Deploying New Commitment </template>
                <template v-slot:description> Add additional commitment and replace expiring commitment </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.primary_function_enable_deploying_new_commitment">
                    <span v-if="settings.primary_function_enable_deploying_new_commitment">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
            </SettingsPanel>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <BaseSettingsPanel :settings="settings" />
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <FlexSettingsPanel :settings="settings" />
          </div>
        </div>
        <div v-if="featureStore.spalSettings && settings.adm_compute_sp_ladder_enabled" class="row sectional">
          <div class="col">
            <SavingsPlanLadderSettingsPanel :settings="settings" />
          </div>
        </div>
        <div v-if="showPrePayment" class="row sectional" data-test-id="prepayment-settings">
          <div class="col">
            <SettingsPanel header="Prepayment">
              <SettingDescription>
                An All or Partial Upfront Prepayment Preference requires a prepay budget. This budget is established and
                approved quarterly and constrains the maximium amount of new commitment prepayment our service can
                deploy.
              </SettingDescription>
              <PrepaymentTable :budget="budget" wide />
            </SettingsPanel>
          </div>
        </div>
        <RequiresRole atleast="Editor">
          <div class="row pt-3">
            <div class="col">
              <div class="updateSettingsConstrainedWidth">
                <p>
                  <strong>Want to update your settings?</strong> We personally review setting adjustments with you to
                  ensure you are safely saving the most possible. Please click the button below to schedule a time to
                  discuss. For urgent questions or updates, please chat with us.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="updateSettingsConstrainedWidth updateButton">
                <CalendlyButton routing-form-id="ckd2-c3j-yqq">
                  <span>Update Settings</span>
                </CalendlyButton>
              </div>
            </div>
          </div>
        </RequiresRole>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
.settingsContainer {
  max-width: 850px;
}

.updateButton {
  min-height: 500px;
}

.calendly {
  margin-top: -32px;
}

.regionSettingRow {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.regionSettingRow:first-child {
  padding-top: 0.75rem;
}
</style>
