<script lang="ts" setup>
import type { OrganizationSettingsSummary } from '@console/services/api.models';

import { computed } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const props = defineProps<{
  settings: OrganizationSettingsSummary;
}>();

const isCspTargetManual = computed(() => !!props.settings.adm_compute_sp_ladder_csp_monthly_target);
</script>

<template>
  <SettingsPanel header="Smart Savings Plans">
    <template #utility>
      <LastUpdated :value="settings.adm_compute_sp_ladder_settings_last_updated" />
    </template>
    <SettingRow class="settingRow">
      <template #label> Adaptive Ladder Prepayment Preference </template>
      <template #description> Upfront prepayment preference for newly deployed Savings Plans </template>
      <template #value>
        <strong>{{ settings.adm_compute_sp_ladder_prepayment_preference }}</strong>
      </template>
    </SettingRow>
    <SettingRow class="settingRow" data-test-id="base-target">
      <template #label> CSP Adaptive Ladder Target Adjust </template>
      <template #description> Placeholder </template>
      <template #value>
        <StatusPill variant="primary">
          {{ isCspTargetManual ? 'Manual' : 'Automatic' }}
        </StatusPill>
      </template>
    </SettingRow>
    <SettingRow v-if="isCspTargetManual" class="settingRow">
      <template #label> CSP Adaptive Ladder Target </template>
      <template #description>
        Placeholder <br />
        Desired amount of CSP laddered spend coverage to maintain (in on-demand equivalent dollars for a 30 day month)
      </template>
      <template #value>
        <strong>{{ NumberHelpers.formatDollars(settings.adm_compute_sp_ladder_csp_monthly_target, 0) }}</strong>
      </template>
    </SettingRow>
    <SettingRow class="settingRow">
      <template #label> CSP Term Distribution </template>
      <template #description> Placeholder </template>
      <template #value>
        <div>
          1 Year:
          <strong
            >{{ NumberHelpers.formatNumber(settings.adm_compute_sp_ladder_csp_one_year_target_percentage) }}%</strong
          >
        </div>
        <div class="pt-2">
          3 Year:
          <strong
            >{{ NumberHelpers.formatNumber(settings.adm_compute_sp_ladder_csp_three_year_target_percentage) }}%</strong
          >
        </div>
      </template>
    </SettingRow>
  </SettingsPanel>
</template>
