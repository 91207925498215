<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { mapState as mapVuexState, mapActions, mapGetters } from 'vuex';

import { BillingOffering } from '@gcp/utilities/constants';
import { useFeatureStore } from '@shared/state/feature.store';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';
import CommittedUseDiscountUtilization from '@gcp/components/savings/CommittedUseDiscountUtilization.vue';
import DailySavingsChart from '@gcp/components/savings/DailySavingsChart.vue';
import DailySpendCoverageChart from '@gcp/components/savings/DailySpendCoverageChart.vue';
import EffectiveSavingsRate from '@gcp/components/savings/EffectiveSavingsRate.vue';
import FlexibleCommittedUseDiscountUtilization from '@gcp/components/savings/FlexibleCommittedUseDiscountUtilization.vue';
import NetSavingsTrendChart from '@gcp/components/savings/NetSavingsTrendChart.vue';
import PortfolioActions from '@gcp/components/savings/PortfolioActions.vue';
import PortfolioAllocation from '@gcp/components/savings/PortfolioAllocation.vue';
import SavingsBreakdown from '@gcp/components/savings/SavingsBreakdown.vue';
import SavingsCallout from '@gcp/components/savings/SavingsCallout.vue';
import SpendCoverageBreakdown from '@gcp/components/savings/SpendCoverageBreakdown.vue';
import SpendCoverageTrendChart from '@gcp/components/savings/SpendCoverageTrendChart.vue';
import TimeframeSelector from '@shared/components/TimeframeSelector.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

export default defineComponent({
  components: {
    SpendCoverageBreakdown,
    Layout,
    PageHeader,
    BuildingState,
    BoxMessage,
    SavingsBreakdown,
    CommittedUseDiscountUtilization,
    EffectiveSavingsRate,
    FlexibleCommittedUseDiscountUtilization,
    PortfolioActions,
    SavingsCallout,
    TimeframeSelector,
    DailySavingsChart,
    DailySpendCoverageChart,
    NetSavingsTrendChart,
    SpendCoverageTrendChart,
    PortfolioAllocation,
  },
  data() {
    return {
      loading: true,
      selectedTimeframe: null,
      billingOffering: BillingOffering.GoogleCloudComputeEngine,
    };
  },
  head: {
    title: 'Google Cloud Compute Savings',
  },
  computed: {
    ...mapVuexState('gcp', ['savingsDashboard']),
    ...mapVuexState('nav', ['context']),
    ...mapGetters('customer', ['selectedCompany', 'isDemo']),
    ...mapGetters('gcp', ['getBillingAccountById', 'savingsTimeframes']),
    ...mapState(useFeatureStore, ['featureFlags']),
    billingAccount() {
      if (!this.context?.id) {
        return null;
      }
      return this.getBillingAccountById(this.context.id);
    },
    hasDailySavings() {
      const isZero = el => el === 0;
      const ds = this.savingsDashboard.dashboard.daily_savings;
      const savings = _.flatMap(ds, el => [
        el.base_savings,
        el.smart_savings,
        el.inherited_savings,
        el.total_other_savings,
      ]);
      return !_.every(savings, s => isZero(s) || _.isUndefined(s));
    },
    hasTrendData() {
      return this.savingsDashboard.dashboard.net_savings_trend_months?.length > 0;
    },
    hasSpendCoverage() {
      return !!this.savingsDashboard.dashboard?.spend_coverage_summary;
    },
    hasDailySpendCoverage() {
      const isZero = el => el === 0;
      const ds = this.savingsDashboard.dashboard.daily_spend_coverage;
      const coverage = _.flatMap(ds, el => [
        el.inherited_spend_coverage,
        el.smart_spend_coverage,
        el.base_spend_coverage,
        el.unbilled_spend_coverage,
        el.other_spend_coverage,
      ]);
      return !_.every(coverage, s => isZero(s) || _.isUndefined(s));
    },
    hasCommittedUseDiscountUtilization() {
      return (
        this.savingsDashboard.dashboard.key_metrics.committed_use_discount_utilization !== null &&
        this.savingsDashboard.dashboard.key_metrics.committed_use_discount_utilization !== undefined
      );
    },
    hasFlexibleCommittedUseDiscountUtilization() {
      return (
        this.savingsDashboard.dashboard.key_metrics.flexible_committed_use_discount_utilization !== null &&
        this.savingsDashboard.dashboard.key_metrics.flexible_committed_use_discount_utilization !== undefined
      );
    },
    hasSpendCoverageTrendData() {
      return this.savingsDashboard.dashboard.spend_coverage_trend?.length > 0;
    },
    hasPortfolioActions() {
      return this.savingsDashboard.dashboard.key_metrics?.action_count > 0;
    },
  },
  async mounted() {
    await this.load(this.$route.params.timeframe);
  },
  methods: {
    ...mapActions('gcp', ['loadSavingsDashboard', 'loadAllBillingAccounts']),
    async load(timeframe) {
      this.loading = true;

      await this.loadSavingsDashboard({
        billingAccountId: this.context.id,
        timeframe,
      });

      this.selectedTimeframe = timeframe
        ? _.find(this.savingsTimeframes, { key: timeframe })
        : _.head(this.savingsTimeframes);

      await this.loadAllBillingAccounts();

      this.loading = false;
    },
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
    async onChange(timeframe) {
      await this.$router.push({
        name: 'google_cloud_compute_savings',
        params: { timeframe: timeframe.key },
        query: this.$route.query,
      });
    },
  },
});
</script>

<template>
  <Layout :loading="loading">
    <template #default>
      <BuildingState v-if="!savingsDashboard" />
      <div v-else>
        <PageHeader wrap-utility>
          <h1>Compute Savings</h1>
          <template v-slot:utility>
            <TimeframeSelector
              :selected="selectedTimeframe"
              :timeframes="savingsTimeframes"
              :finalized="savingsDashboard.dashboard.is_finalized"
              :data-through-date="savingsDashboard.dashboard.data_through_date"
              @change="onChange"
            />
          </template>
        </PageHeader>
        <div v-if="savingsDashboard.subscription_start_date" class="row sectional">
          <div class="col">
            <BoxMessage type="info">
              <strong
                >{{ formatDate(savingsDashboard.subscription_start_date) }} was your first full day with the ProsperOps
                service enabled.</strong
              >
              This month is considered a transition month as the savings results reflect a blend of before and after
              ProsperOps management.
            </BoxMessage>
          </div>
        </div>

        <div v-if="savingsDashboard.dashboard.is_effective_savings_rate_adjusted" class="row sectional">
          <div class="col">
            <BoxMessage type="warning">
              <div>
                <strong
                  >The Effective Savings Rate calculation is adjusted to account for certain credits (such as promotions
                  or subscription benefits).</strong
                >
                These credits fall outside the scope of rate optimization and will remain in effect until they expire or
                are otherwise exhausted. Please
                <router-link :to="{ name: 'help' }">contact us</router-link>
                for further guidance.
              </div>
            </BoxMessage>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <SavingsCallout
              :month="savingsDashboard.dashboard.data_through_date"
              :summary="savingsDashboard.dashboard.savings_summary"
              :is-finalized="savingsDashboard.dashboard.is_finalized"
              :billing-offering="billingOffering"
            />
          </div>
        </div>
        <div class="row sectional">
          <div class="col-xl-auto effectiveSavingsRate">
            <EffectiveSavingsRate
              :current="savingsDashboard.dashboard.effective_savings_rate.current"
              :previous="savingsDashboard.dashboard.effective_savings_rate.previous"
            />
          </div>
          <div class="col">
            <SavingsBreakdown
              v-if="savingsDashboard.dashboard.savings_breakdown_periods?.length > 0 && billingAccount"
              :billing-account-id="billingAccount.billing_account_id"
              :savings="savingsDashboard"
              :company="selectedCompany"
              :demo="isDemo"
              service="compute"
            />
          </div>
        </div>
        <div class="row sectional">
          <div v-if="hasPortfolioActions" class="col-lg">
            <PortfolioActions :count="savingsDashboard.dashboard.key_metrics.action_count" />
          </div>
          <div v-if="hasCommittedUseDiscountUtilization" class="col-lg">
            <CommittedUseDiscountUtilization
              :utilization="savingsDashboard.dashboard.key_metrics.committed_use_discount_utilization"
            />
          </div>
          <div v-if="hasFlexibleCommittedUseDiscountUtilization" class="col-lg">
            <FlexibleCommittedUseDiscountUtilization
              :utilization="savingsDashboard.dashboard.key_metrics.flexible_committed_use_discount_utilization"
            />
          </div>
        </div>
        <div v-if="hasDailySavings" class="row sectional">
          <div class="col">
            <DailySavingsChart
              :daily-savings="savingsDashboard.dashboard.daily_savings"
              :month-start="savingsDashboard.selected_timeframe.key"
            />
          </div>
        </div>
        <div v-if="hasTrendData" class="row sectional">
          <div class="col">
            <NetSavingsTrendChart :net-savings-trend="savingsDashboard.dashboard.net_savings_trend_months" />
          </div>
        </div>
        <div v-if="hasSpendCoverage" class="row sectional stack-sectional">
          <div class="col">
            <PortfolioAllocation
              v-if="savingsDashboard.dashboard.portfolio_allocation"
              :portfolio-allocation="savingsDashboard.dashboard.portfolio_allocation"
            />
          </div>
          <div class="col">
            <SpendCoverageBreakdown
              v-if="savingsDashboard.dashboard.spend_coverage_summary"
              :spend-coverage="savingsDashboard.dashboard.spend_coverage_summary"
              :finalized="savingsDashboard.dashboard.is_finalized"
            />
          </div>
        </div>
        <div v-if="hasDailySpendCoverage" class="row sectional">
          <div class="col">
            <DailySpendCoverageChart
              :daily-spend-coverage="savingsDashboard.dashboard.daily_spend_coverage"
              :month-start="savingsDashboard.selected_timeframe.key"
            />
          </div>
        </div>
        <div v-if="hasSpendCoverageTrendData" class="row sectional">
          <div class="col">
            <SpendCoverageTrendChart :spend-coverage-trend="savingsDashboard.dashboard.spend_coverage_trend" />
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.effectiveSavingsRate {
  flex-basis: auto;

  @include media-breakpoint-up(xl) {
    flex-basis: 380px;
  }
}
</style>
