<script setup lang="ts">
import type { Ec2InventoryMetrics } from '@console/services/aws/arm/api.models';

import { h } from 'vue';

import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const ResourcesIcon = h('div', { class: 'fa-2x' }, [h(BaseIcon, { name: 'layer-group', variant: 'fas' })]);
const ScheduledIcon = h('div', { class: 'fa-2x' }, [h(BaseIcon, { name: 'calendar-alt', variant: 'far' })]);
const RunningIcon = h('div', { class: 'fa-2x' }, [h(BaseIcon, { name: 'play', variant: 'fas' })]);

const StoppedIcon = h('div', { class: 'fa-2x' }, [h(BaseIcon, { name: 'stop', variant: 'fas' })]);

const props = defineProps<{
  metricData: Ec2InventoryMetrics;
}>();

const metricConfig = {
  organization_total_resources: {
    name: 'Total Resources',
    unit: 'count',
    icon: ResourcesIcon,
  },
  organization_scheduled_instances: {
    name: 'Scheduled Resources',
    unit: 'count',
    icon: ScheduledIcon,
  },
  organization_scheduled_running_instances: {
    name: 'Scheduled Resources: Running',
    unit: 'count',
    icon: RunningIcon,
  },
  organization_scheduled_stopped_instances: {
    name: 'Scheduled Resources: Stopped',
    unit: 'count',
    icon: StoppedIcon,
  },
};
</script>

<template>
  <div class="row sectional">
    <div class="col-lg-6 col-xl-3">
      <MetricPanel
        :name="metricConfig.organization_total_resources.name"
        :icon="metricConfig.organization_total_resources.icon"
        color="primary"
      >
        <div>{{ props.metricData.total_resources }}</div>
      </MetricPanel>
    </div>
    <div class="col-lg-6 col-xl-3">
      <MetricPanel
        :name="metricConfig.organization_scheduled_instances.name"
        :icon="metricConfig.organization_scheduled_instances.icon"
        color="secondary"
      >
        <div>{{ props.metricData.scheduled_resources }}</div>
      </MetricPanel>
    </div>
    <div class="col-lg-6 col-xl-3">
      <MetricPanel
        :name="metricConfig.organization_scheduled_running_instances.name"
        :icon="metricConfig.organization_scheduled_running_instances.icon"
        color="success"
      >
        <div>{{ props.metricData.running_resources }}</div>
      </MetricPanel>
    </div>
    <div class="col-lg-6 col-xl-3">
      <MetricPanel
        :name="metricConfig.organization_scheduled_stopped_instances.name"
        :icon="metricConfig.organization_scheduled_stopped_instances.icon"
        color="danger"
      >
        <div>{{ props.metricData.stopped_resources }}</div>
      </MetricPanel>
    </div>
  </div>
</template>
