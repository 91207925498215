<script setup lang="ts">
import type { AwsAdmNonComputeOfferingCode, NonComputeSettingsSummary } from '@console/services/api.models';

import { ref, onMounted, computed } from 'vue';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import GlobalSettings from './GlobalSettings.vue';
import MatchKeySettings from './MatchKeySettings.vue';
import RequiresRole from '@console/components/RequiresRole.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import CalendlyButton from '@console/components/widgets/CalendlyButton.vue';
import Layout from '@console/Layout.vue';
import BoxMessageV2 from '@shared/design/BoxMessageV2.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import StatusPill from '@shared/design/StatusPill.vue';

type Props = {
  service: AwsAdmNonComputeOfferingCode;
};

const props = defineProps<Props>();

const store = useVuexStore();
const awsOrganizationId = computed(() => store.getters['aws/selectedOrganizationId']);
const orgSettings = computed(() => store.getters['aws/selectedOrganizationSettings']);

const settings = ref<NonComputeSettingsSummary>();
onMounted(async () => {
  const response = await customerService.getNonComputeSettings(awsOrganizationId.value, props.service);
  settings.value = response?.data as NonComputeSettingsSummary;
});

const pftName = AwsServiceHelpers.getNonComputePftSettingName(props.service);
const pftEnabled = computed(() => orgSettings.value?.[pftName]);

const routingFormIds = {
  elasti_cache: 'ckk5-dqw-39h',
  memory_db: 'cp6d-wfh-3fj',
  open_search: 'ckd2-xzz-4qy',
  rds: 'cp2t-mn8-jfc',
  redshift: 'cn86-kt3-5gk',
};

const routingFormId = computed(() => routingFormIds[props.service]);
</script>

<template>
  <Layout :loading="!settings">
    <template #default>
      <div v-if="settings" class="settingsContainer">
        <PageHeader>
          <div class="titleContainer">
            <h1 class="pageTitle">{{ AwsServiceHelpers.getDisplayName(service) }} Settings</h1>
          </div>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <BoxMessageV2>
              <div>
                Global settings serve as defaults and apply broadly unless explicitly overridden by a Match Key setting.
                Global settings should be configured for general usage patterns and Match Key settings should be used
                when there is specific context that necessitates a different strategy (e.g. migrating away from a match
                key).
              </div>
            </BoxMessageV2>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <SettingsPanel header="Status">
              <SettingRow class="settingRow">
                <template v-slot:label> Managing Commitment </template>
                <template v-slot:description>
                  Deploy new commitment using an Adaptive Laddering strategy which dynamically adjusts based on usage
                  changes
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="pftEnabled">
                    <span v-if="pftEnabled">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
            </SettingsPanel>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <GlobalSettings :settings="settings" />
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <MatchKeySettings :settings="settings" />
          </div>
        </div>
        <RequiresRole atleast="Editor">
          <div class="row pt-3">
            <div class="col">
              <div class="updateSettingsConstrainedWidth">
                <p>
                  <strong>Want to update your settings?</strong> We personally review setting adjustments with you to
                  ensure you are safely saving the most possible. Please click the button below to schedule a time to
                  discuss. For urgent questions or updates, please chat with us.
                </p>
              </div>
            </div>
          </div>
          <div class="scheduleUpdate">
            <CalendlyButton :routing-form-id="routingFormId">
              <span>Update Settings</span>
            </CalendlyButton>
          </div>
        </RequiresRole>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
.titleContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.pageTitle {
  flex-grow: 1;
  min-width: max-content;
  margin-bottom: 0;
}

.updatedDate {
  align-self: flex-end;
  min-width: max-content;
}

.settingsContainer {
  max-width: 1200px;
}

.scheduleUpdate {
  min-height: 500px;
}
</style>
