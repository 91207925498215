// These values should match ProsperOps.Models.Billing.BillingOffering in core
export enum BillingOffering {
  AwsCompute = 'AutonomousDiscountManagementForAwsCompute',
  AwsElastiCache = 'AutonomousDiscountManagementForAwsElastiCache',
  AwsMemoryDb = 'AutonomousDiscountManagementForAwsMemoryDb',
  AwsOpenSearch = 'AutonomousDiscountManagementForAwsOpenSearch',
  AwsRds = 'AutonomousDiscountManagementForAwsRds',
  AwsRedshift = 'AutonomousDiscountManagementForAwsRedshift',
  GoogleCloudComputeEngine = 'AutonomousDiscountManagementForGcpComputeEngine',
  GoogleCloudCloudSQL = 'AutonomousDiscountManagementForGoogleCloudSQL',
  SchedulerForAwsEc2 = 'AutonomousResourceManagementSchedulerForAwsEc2',
}

export const BillingOfferingToDisplayName = {
  [BillingOffering.GoogleCloudComputeEngine]: 'Compute',
  [BillingOffering.GoogleCloudCloudSQL]: 'Cloud SQL',
};
