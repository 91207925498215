<script lang="ts" setup>
import type { ComputeSpendLink } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import moment from 'moment';
import * as Pako from 'pako';
import { computed } from 'vue';

const props = defineProps<{
  data: ComputeSpendLink;
  selectedTimeframe: MonthStart;
  dataThroughDate: string;
}>();

const startDate = computed(() => moment(props.selectedTimeframe.key).set('date', 1).format('YYYY-MM-DD'));
const endDate = computed(() => {
  const dataThrough = moment(props.dataThroughDate);
  const dataThroughMonth = dataThrough.month();
  const timeFrame = moment(props.selectedTimeframe.key).endOf('month');

  if (dataThroughMonth === timeFrame.month()) {
    timeFrame.set('date', dataThrough.date());
  }

  return timeFrame.format('YYYY-MM-DD');
});

const linkData = computed(() => {
  return {
    currency: 'USD',
    dateRange: 'Custom',
    query: {
      type: 'AmortizedCost',
      dataSet: {
        granularity: 'None',
        aggregation: {
          totalCost: {
            name: 'Cost',
            function: 'Sum',
          },
          totalCostUSD: {
            name: 'CostUSD',
            function: 'Sum',
          },
        },
        grouping: [
          {
            type: 'Dimension',
            name: 'ServiceName',
          },
        ],
        filter: {
          and: [
            {
              Dimensions: {
                Name: 'MeterCategory',
                Operator: 'In',
                Values: props.data.meter_categories,
              },
            },
            {
              Dimensions: {
                Name: 'Meter',
                Operator: 'In',
                Values: props.data.meters,
              },
            },
          ],
        },
      },
      timeframe: 'Custom',
      timePeriod: {
        from: startDate.value,
        to: endDate.value,
      },
    },
    chart: 'GroupedColumn',
    accumulated: 'false',
    scope: props.data.scope.substring(1),
    displayName: 'AccumulatedCosts',
  };
});

const dashboardLink = () => {
  const view = linkData.value;
  const viewStr = JSON.stringify(view);
  const compressedByteArray = Pako.gzip(viewStr);
  const compressed = String.fromCharCode(...new Uint8Array(compressedByteArray));
  const compressedBase64 = btoa(compressed);
  const compressedUrlEncoded = encodeURIComponent(compressedBase64);

  const origin = 'portal.azure.com';
  const scopeId = encodeURIComponent(view.scope);
  const url = `https://${origin}/#view/Microsoft_Azure_CostManagement/Menu/open/CostAnalysis/scope/${scopeId}`;

  return `${url}/view/${compressedUrlEncoded}`;
};
</script>

<template>
  <small v-if="props.data">
    <a :href="dashboardLink()" target="_blank">Show me this in the Azure Portal</a>
  </small>
</template>
