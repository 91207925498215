<script setup lang="ts">
import type { AwsDiscountInstrumentBreakdown, AwsComputeSavingsDashboard } from '@console/services/aws/savings.models';
import type { NormalizableFields } from '@shared/utilities/typescript_helpers';

import moment from 'moment';
import { computed } from 'vue';

import Currency from '@shared/components/Currency.vue';
import Modal from '@shared/design/modals/Modal.vue';

type Props = {
  dashboard: AwsComputeSavingsDashboard;
  demo?: boolean;
  allDiscounts?: boolean;
  showNormalized: boolean;
};

type DiscountInstrumentField = NormalizableFields<AwsDiscountInstrumentBreakdown>;

type RowConfig = {
  label: string;
  field: DiscountInstrumentField;
};

type SectionConfig = {
  header: string;
  total_field: DiscountInstrumentField;
  rows: RowConfig[];
};

const model = defineModel<boolean>({ required: true });
const props = defineProps<Props>();
const currentPeriod = props.dashboard.discount_instrument_breakdown;

const sectionConfig: SectionConfig[] = [
  {
    header: 'RI and SP Savings',
    total_field: 'total_commitment_discount_savings',
    rows: [
      {
        label: 'Reserved Instance Savings',
        field: 'ec2_reserved_instance_savings',
      },
      {
        label: 'Private Rate Savings on Reserved Instances',
        field: 'private_rate_ec2_reserved_instance_savings',
      },
      {
        label: 'Volume Savings on Standard Reserved Instances',
        field: 'ec2_standard_reserved_instance_volume_discount_savings',
      },
      {
        label: 'Savings Plan Savings',
        field: 'savings_plan_savings',
      },
      {
        label: 'Private Rate Savings on Savings Plans',
        field: 'private_rate_savings_plan_savings',
      },
    ],
  },
  {
    header: 'Spot Savings',
    total_field: 'total_spot_savings',
    rows: [
      {
        label: 'EC2 Spot Savings',
        field: 'ec2_spot_savings',
      },
      {
        label: 'Private Rate Savings on EC2 Spot',
        field: 'private_rate_ec2_spot_savings',
      },
      {
        label: 'Fargate Spot Savings',
        field: 'fargate_spot_savings',
      },
      {
        label: 'Private Rate Savings on Fargate Spot',
        field: 'private_rate_fargate_spot_savings',
      },
    ],
  },
  {
    header: 'Other Private Rate Savings',
    total_field: 'total_other_private_rate_savings',
    rows: [
      {
        label: 'Private Rate Savings on EC2 On-Demand',
        field: 'private_rate_ec2_on_demand_savings',
      },
      {
        label: 'Private Rate Savings on EC2 Capacity Blocks',
        field: 'private_rate_ec2_capacity_block_reservation_savings',
      },
      {
        label: 'Private Rate Savings on Fargate On-Demand',
        field: 'private_rate_fargate_on_demand_savings',
      },
      {
        label: 'Private Rate Savings on Lambda On-Demand',
        field: 'private_rate_lambda_on_demand_savings',
      },
    ],
  },
];

const sections = computed(() =>
  sectionConfig
    .map(section => ({
      ...section,
      rows: section.rows.filter(r => !!currentPeriod?.[r.field]),
    }))
    .filter(section => section.rows.length > 0)
);

const monthStart = computed(() => moment(props.dashboard.data_through_date).format('MMMM YYYY'));

const getValue = (field: DiscountInstrumentField) =>
  !!currentPeriod && (props.showNormalized ? currentPeriod[`${field}_normalized`] : currentPeriod[field]);
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Modal v-model="model" size="lg" title="Gross Savings Breakdown Including All Discounts">
    <div v-if="currentPeriod">
      <table class="table costBreakdown mb-0">
        <thead>
          <tr>
            <td>
              <strong>{{ monthStart }}</strong> ({{ showNormalized ? 'Normalized' : 'Actual' }})
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-for="section in sections" :key="section.header">
            <tr class="sectionHeader">
              <td>
                {{ section.header }}
              </td>
              <td>
                <Currency :value="getValue(section.total_field)" />
              </td>
            </tr>
            <tr class="sectionBreakdown">
              <td colspan="2">
                <table>
                  <tbody>
                    <tr v-for="row in section.rows" :key="row.label">
                      <td>
                        {{ row.label }}
                      </td>
                      <td>
                        <Currency :value="getValue(row.field)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td>Total Savings</td>
            <td>
              <Currency :value="getValue('total_savings')" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.costBreakdown {
  thead td {
    height: inherit;
    padding-top: 0;
    padding-bottom: 1em;
    border: none;
  }

  td {
    height: 1.5rem;
    vertical-align: middle;
  }

  td:nth-child(2) {
    text-align: right;
  }

  .sectionHeader {
    font-weight: 500;
  }

  .sectionBreakdown {
    table {
      width: 100%;
      td:first-child {
        padding-left: 1.8rem;
      }
    }

    > td {
      padding: 0 !important;
      font-size: 0.85rem;
      border-top: none;
    }
  }

  tfoot {
    font-weight: 600;
    background-color: $gray-50;
  }
}
</style>
