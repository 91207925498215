import type { AwsAdmNonComputeOfferingCode } from '@console/services/api.models';

import { getVuexStore } from '@console/state/vuex/store';

const notNewOrganization = () => {
  const store = getVuexStore();
  return !store.getters['aws/isNewOrganization'];
};

const noActiveSubscriptionOrNotConfiguredOrg = () => {
  const store = getVuexStore();
  const noActiveSubscription = !store.getters['customer/isSubscribed'];
  const organizationNotConfigured = !store.getters['aws/selectedOrganizationIsConfigured'];
  return noActiveSubscription || organizationNotConfigured;
};

const hasSingleOrgSelected = () => {
  const store = getVuexStore();
  return !store.getters['aws/isMultiOrgSummarySelected'];
};

const hasNonComputeOffering = (service: AwsAdmNonComputeOfferingCode) => () => {
  const store = getVuexStore();
  const services = store.getters['aws/selectedOrganizationNonComputeOfferings'];
  return services[service];
};

export { notNewOrganization, noActiveSubscriptionOrNotConfiguredOrg, hasSingleOrgSelected, hasNonComputeOffering };
