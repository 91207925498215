<script lang="ts" setup>
import type { Tenant } from '@azure/services/subscriptions';

import _ from 'lodash';
import { onMounted, ref } from 'vue';

import { getSubscriptionsGroupedByTenant } from '@azure/services/subscriptions';
import { useAzureStore } from '@azure/state';

import AzureTenantFilterList from '@azure/components/tenants/AzureTenantFilterList.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const azureState = useAzureStore();
const loading = ref(true);
const tenants = ref<Tenant[]>([]);

onMounted(async () => {
  loading.value = true;
  const billingScopeId = azureState.getActiveBillingScopeId();
  if (billingScopeId) {
    const result = await getSubscriptionsGroupedByTenant(billingScopeId);
    tenants.value = result;
  }
  loading.value = false;
});

const validateTenant = async (tenant: Tenant) => {
  tenant.validated = true;

  // Set all subscriptions to active - this will be an API call in the future
  _.each(tenant.subscriptions, s => {
    s.status = 'Active';
  });
};

const validateAll = () => {
  _.each(tenants.value, t => {
    validateTenant(t);
  });
};
</script>

<template>
  <div>
    <PageHeader wrap-utility>
      <h1>Azure Tenants</h1>
      <AzureTenantFilterList :validate-tenant="validateTenant" :validate-all="validateAll" :tenants="tenants" />
    </PageHeader>
  </div>
</template>
