<script lang="ts" setup>
import { useSlots } from 'vue';

import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps<{
  header?: string;
  noUnderline?: boolean;
}>();

const slots = useSlots();
</script>

<template>
  <PanelSection :header="props.header" rounded new-header :underlined="!props.noUnderline" class="settingsPanel">
    <template v-if="slots.header" #header>
      <slot name="header" />
    </template>

    <template v-if="slots.utility" #utility>
      <slot name="utility" />
    </template>

    <slot />
  </PanelSection>
</template>

<style lang="scss" scoped>
.settingsPanel {
  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}
</style>
