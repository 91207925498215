<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';

const vuexStore = useVuexStore();

const s3BucketNameInput = ref<HTMLInputElement | null>();
const props = defineProps<{ s3BucketName?: string }>();
const emits = defineEmits(['update:s3BucketName']);

const awsAccountFriendlyName = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.friendly_name
);
const awsAccountNumber = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.aws_account_number
);

onMounted(() => {
  s3BucketNameInput.value?.focus();
});
</script>

<template>
  <div class="mb-2">S3 Bucket Name</div>
  <input
    ref="s3BucketNameInput"
    :value="props.s3BucketName"
    class="form-control form-control-sm bucketFormInput"
    placeholder="e.g. prosperops-data-export"
    autocomplete="off"
    @input="event => emits('update:s3BucketName', (event.target as HTMLInputElement)?.value.trim())"
  />
  <div class="text-muted mt-3 note">
    Note: The S3 Bucket must be in the AWS management account <strong>{{ awsAccountFriendlyName }}</strong> ({{
      awsAccountNumber
    }}).
  </div>
</template>

<style lang="scss" scoped>
.note {
  font-size: 0.85rem;
}
.bucketFormInput {
  width: 300px;
}
</style>
