<script setup lang="ts">
import type { GranularRole } from '@shared/state/auth.store';

import { useVuelidate } from '@vuelidate/core';
import { required, email, requiredIf } from '@vuelidate/validators';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAzureStore } from '@azure/state';
import { getRoleDescriptions } from '@console/components/users/role_descriptions';
import { useVuexStore } from '@console/state/vuex/store';
import { RoleTypes } from '@shared/services/customer.models';
import { useUsersStore } from '@shared/state/users.store';

import GranularRoleSelector from '@console/components/users/GranularRoleSelector.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import TextInput from '@shared/design/TextInput.vue';

const router = useRouter();
const store = useVuexStore();
const userStore = useUsersStore();
const azureStore = useAzureStore();

const hasMoreThanOneResource = computed(() => {
  return (
    Object.values(store.state.aws.awsOrganizations).length +
      Object.values(store.state.gcp.billingAccounts).length +
      Object.values(azureStore.billingScopes ?? {}).length >
    1
  );
});
const roles = computed(() => {
  return getRoleDescriptions(hasMoreThanOneResource.value);
});

interface FormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  selectedRole: RoleTypes;
  selectedGranularRoles: GranularRole[];
}
const data = reactive<FormData>({
  firstName: '',
  lastName: '',
  emailAddress: '',
  selectedRole: RoleTypes.Viewer,
  selectedGranularRoles: [],
});
const submitting = ref(false);

const notGmail = (value: string) => !value.includes('@gmail.com');
const v$ = useVuelidate(
  {
    data: {
      firstName: { required },
      lastName: { required },
      emailAddress: { required, email, notGmail },
      selectedRole: { required },
      selectedGranularRoles: {
        required: requiredIf(() => data.selectedRole === RoleTypes.Granular),
      },
    },
  },
  { data },
  { $scope: false }
);

const submit = async () => {
  submitting.value = true;
  if (data.selectedRole === RoleTypes.Granular) {
    await userStore.addGranularUser(data.firstName, data.lastName, data.emailAddress, data.selectedGranularRoles);
  } else {
    await userStore.addUser(data.firstName, data.lastName, data.emailAddress, data.selectedRole);
  }
  submitting.value = false;
  await router.push({
    name: 'user_management',
    params: {
      flashMessage: 'User successfuly added!',
    },
  });
};
</script>

<template>
  <Layout>
    <template #default>
      <div class="row pb-3">
        <div class="col">
          <router-link :to="{ name: 'user_management' }">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to User Management
          </router-link>
        </div>
      </div>
      <PageHeader>
        <h1>Add New User</h1>
      </PageHeader>
      <BoxMessage type="info" class="pt-4 pb-4">
        <p class="mb-0">New users will be emailed further instructions to set their password.</p>
      </BoxMessage>
      <form @submit.prevent="submit">
        <div class="row pt-4">
          <div class="col-sm-4">
            <label for="firstName" class="d-block required text-muted"> First Name </label>
            <TextInput id="firstName" v-model="data.firstName" class="w-100" placeholder="Required" with-focus />
          </div>
          <div class="col-sm-4">
            <label for="lastName" class="d-block required text-muted"> Last Name </label>
            <TextInput id="lastName" v-model="data.lastName" class="w-100" placeholder="Required" />
          </div>
          <div class="col-sm-4">
            <label for="emailAddress" class="d-block required text-muted"> Email Address </label>
            <TextInput id="emailAddress" v-model="data.emailAddress" class="w-100" placeholder="Required" />
          </div>
        </div>
        <div class="row pt-4">
          <div class="col pt-2">
            <label for="role" class="d-block required text-muted"> Role Membership </label>
            <ul class="list-group roleMembership">
              <li v-for="role in roles" :key="role.name" class="list-group-item rounded-sm">
                <div class="role">
                  <div class="roleOption">
                    <input :id="role.name" v-model="data.selectedRole" type="radio" name="role" :value="role.name" />
                  </div>
                  <label :for="role.name" class="roleText w-100">
                    <div class="pb-2 roleName">
                      {{ role.name === RoleTypes.Granular ? 'Custom' : role.name }}
                    </div>
                    <p class="mb-0 text-muted">
                      {{ role.text }}
                    </p>
                  </label>
                </div>
                <div v-if="role.name === RoleTypes.Granular && data.selectedRole === RoleTypes.Granular" class="ml-5">
                  <GranularRoleSelector
                    v-model:selected-granular-roles="data.selectedGranularRoles"
                    @update:selected-granular-roles="
                      newGranularRoles => (data.selectedGranularRoles = newGranularRoles)
                    "
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col pt-4">
            <div class="buttons">
              <form-submit-button
                type="submit"
                variant="primary"
                class="rounded-sm"
                :disabled="v$.$invalid"
                :loading="submitting"
              >
                Add User
                <template v-slot:loading> Adding... </template>
              </form-submit-button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.required::before {
  display: inline-block;
  font-weight: 800;
  color: map-get($theme-colors, 'danger');
  content: '*';
}

.role {
  display: flex;
}

.roleText {
  padding: 0.7rem;
  font-size: 1rem;
  line-height: 1.2;
}

.roleText:hover {
  cursor: pointer;
}

.roleOption {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  padding: 0.7rem;
}

.roleName {
  font-weight: 500;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
}

.roleMembership li {
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
