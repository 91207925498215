/* eslint-disable camelcase */

export interface MonthStart {
  key: string;
  friendly_name: string;
}

export interface ComputeSpendCoverageTrend {
  month_start: string;
  on_demand?: number;
  on_demand_percentage?: number;
  smart_resource_cud?: number;
  smart_resource_cud_percentage?: number;
  smart_spend_cud?: number;
  smart_spend_cud_percentage?: number;
  base_spend_cud?: number;
  base_spend_cud_percentage?: number;
  inherited_resource_cud?: number;
  inherited_resource_cud_percentage?: number;
  inherited_spend_cud?: number;
  inherited_spend_cud_percentage?: number;
  unbilled_cud?: number;
  unbilled_cud_percentage?: number;
  base_spend_coverage_target?: number;
  overall_spend_coverage_percentage?: number;
}

export interface PortfolioAllocation {
  on_demand_percentage?: number;
  unbilled_one_year_percentage?: number;
  unbilled_three_year_percentage?: number;
  other_percentage?: number;
  inherited_one_year_percentage?: number;
  inherited_three_year_percentage?: number;
  base_one_year_percentage?: number;
  base_three_year_percentage?: number;
  smart_one_year_percentage?: number;
  smart_three_year_percentage?: number;
  sustained_use_discount_percentage?: number;
  on_demand_negotiated_percentage?: number;
  free_tier_percentage?: number;
  other_credits_percentage?: number;
  unbilled_one_year_discount_percentage?: number;
  unbilled_three_year_discount_percentage?: number;
  other_discount_percentage?: number;
  inherited_one_year_discount_percentage?: number;
  inherited_three_year_discount_percentage?: number;
  base_one_year_discount_percentage?: number;
  base_three_year_discount_percentage?: number;
  smart_one_year_discount_percentage?: number;
  smart_three_year_discount_percentage?: number;
  effective_discount_percentage?: number;
  sustained_use_discount_discount_percentage?: number;
  on_demand_negotiated_discount_percentage?: number;
  free_tier_discount_percentage?: number;
  other_credits_discount_percentage?: number;
}

export interface ComputeSpendCoverageBreakdown {
  base_target?: number;

  unbilled_spend_coverage?: number;
  unbilled_spend_coverage_percentage?: number;
  inherited_spend_coverage?: number;
  inherited_spend_coverage_percentage?: number;
  base_spend_coverage?: number;
  base_spend_coverage_percentage?: number;
  other_spend_coverage?: number;
  other_spend_coverage_percentage?: number;
  smart_spend_coverage?: number;
  smart_spend_coverage_percentage?: number;

  // Other breakdown
  sustained_use_discount_coverage?: number;
  sustained_use_discount_coverage_percentage?: number;
  on_demand_negotiated_coverage?: number;
  on_demand_negotiated_coverage_percentage?: number;
  free_tier_coverage?: number;
  free_tier_coverage_percentage?: number;
  other_credits_coverage?: number;
  other_credits_coverage_percentage?: number;

  overall_spend_coverage?: number;
  overall_spend_coverage_percentage?: number;
}

export interface OtherSavingsCategory {
  name: string;
  field: 'sustained_use_discount' | 'on_demand_negotiated' | 'free_tier' | 'other_credits';
}

export const otherSavingsCategories: OtherSavingsCategory[] = [
  { name: 'SUD', field: 'sustained_use_discount' },
  { name: 'On-Demand Contract', field: 'on_demand_negotiated' },
  { name: 'Free Tier', field: 'free_tier' },
  { name: 'Other Credits', field: 'other_credits' },
];

/* Commitment Burndown */

export interface CommitmentBurndownSeries {
  label: string;
  data: (number | null)[];
}

export interface CommitmentBurndown {
  series_data: CommitmentBurndownSeries[];
}

/* Remaining Commitment */

export interface TotalRemainingCommitmentLineItem {
  label: string;
  dollars: number;
  percentage: number;
}

export interface TotalRemainingCommitment {
  line_items: TotalRemainingCommitmentLineItem[];
  overall_dollars: number;
}

/* Commitment Dashboard */

export interface CommitmentLockInRiskDetailSeries {
  label: string;
  value: number;
}

export interface ComputeCommitmentLockInRiskDetail {
  overall: number;
  series_data: CommitmentLockInRiskDetailSeries[];
}

export interface CurrentCommitmentLockInRiskSeries {
  label: string;
  value: number;
}

export interface WeightedAverageDurationDetailSeries {
  label: string;
  value: number;
}

export interface WeightedAverageDurationDetail {
  series_data: WeightedAverageDurationDetailSeries[];
  overall: number;
}

export interface CommitmentLockInRiskTrend {
  overall: (number | null)[];
}

export interface FilteredComputeCommitmentsDashboard {
  daily_commitment_burndown: CommitmentBurndown;
  cumulative_commitment_burndown: CommitmentBurndown;
  commitment_lock_in_risk_detail: ComputeCommitmentLockInRiskDetail;
  weighted_average_duration_detail: WeightedAverageDurationDetail;
  total_remaining_commitment: TotalRemainingCommitment;
  commitment_lock_in_risk_trend: CommitmentLockInRiskTrend;
}

export interface CommitedUseDiscountExpiration {
  plan: string;
  end_date: Date;
  is_seed: boolean;
  hourly_price_v_cpu: number;
  hourly_price_gb_ram: number;
  percentage_of_total_match_key_commitment: number;
}

export interface CommitedUseDiscountExpirationTimeline {
  project_id: string;
  match_key: string;
  friendly_name: string;
  expirations: CommitedUseDiscountExpiration[];
}

export interface ComputeCommitmentsDashboard {
  version: number | null;
  data_through_date: string;
  match_keys: string[];
  baseline_commitment_lock_in_risk?: number;
  filtered_dashboards: { [key: string]: FilteredComputeCommitmentsDashboard };
  commited_use_discount_expiration_timelines: CommitedUseDiscountExpirationTimeline[];
}

export interface ComputeCommitmentsDashboardResponse {
  id: string;
  gcp_billing_account_id: string;
  dashboard: ComputeCommitmentsDashboard;
}

export interface MatchKeySettingsSummary {
  match_key: string;
  friendly_region_name: string;
  mode: string;
  discount_shift: boolean;
  three_year_minimum_gb_ram: number;
  three_year_minimum_v_cpu: number;
}

export interface SettingsSummary {
  // TODO remove nullable when API changes to always report it
  cloud_sql_commitment_management_enabled?: boolean;
  commitment_management_enabled: boolean;
  savings_analysis_completed: boolean;
  discount_shift?: boolean;
  base_one_year_target_hourly_on_demand_equivalent: number;
  base_three_year_target_hourly_on_demand_equivalent: number;
  base_last_updated?: Date;
  smart_last_updated?: Date;
  match_key_last_updated?: Date;
  mode?: string;
  match_keys: Array<MatchKeySettingsSummary>;
}
