<script setup lang="ts">
import type { EventLogMessage, ScheduleModificationMessage } from '@console/services/aws/arm/api.models';

import { computed } from 'vue';

import PowerChangedTooltip from '@aws/components/arm/event-logs/PowerChangedTooltip.vue';
import ScheduleUpdatedTooltip from '@aws/components/arm/event-logs/ScheduleUpdatedTooltip.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const props = defineProps<{
  params: {
    statusMessage: string;
    originalLogMessage: EventLogMessage;
  };
}>();

const hasWarning = computed(() => {
  const message = props.params.originalLogMessage;
  if (message.event_type === 'ScheduleUpdated') {
    const error = (message as ScheduleModificationMessage).error_severity;
    return error === 'Warning' && message.activity_type !== 'ScheduleRemoved';
  }
  return false;
});

function shouldShowTooltip(message: EventLogMessage): boolean {
  if (message.event_type === 'ScheduleUpdated') {
    // Do not return tool tip when a schedule was removed.
    return message.activity_type !== 'ScheduleRemoved';
  }

  if (message.event_type === 'PowerAction') {
    return message.action_outcome === 'Failed';
  }

  return false;
}
</script>

<template>
  <Tooltip placement="left" size="lg" overflow-scroll-height="500px">
    <div>
      {{ props.params.statusMessage }}
      <span v-if="hasWarning" class="ml-1"><BaseIcon name="exclamation-triangle" class="text-warning"></BaseIcon></span>
    </div>
    <template v-if="shouldShowTooltip(props.params.originalLogMessage)" #tooltip>
      <div class="eventLogStatusTooltip">
        <ScheduleUpdatedTooltip
          v-if="props.params.originalLogMessage.event_type === 'ScheduleUpdated'"
          :event-log="props.params.originalLogMessage"
        />
        <PowerChangedTooltip
          v-if="props.params.originalLogMessage.event_type === 'PowerAction'"
          :event-log="props.params.originalLogMessage"
        />
      </div>
    </template>
  </Tooltip>
</template>

<style lang="css" scoped>
@use '@shared/scss/fonts.scss';
@use '@shared/scss/colors.scss';

.eventLogStatusTooltip {
  top: 5px;
  max-width: 500px;
  padding: 0.5rem 0.5rem 0.5rem;
  font-family: fonts.$font-family-sans-serif;
  background-color: colors.$gray-100;
  border: 1px solid colors.$gray-300;
  border-radius: 0;
}
</style>
