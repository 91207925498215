<script setup lang="ts">
import type {
  ScheduleModificationMessage,
  ErrorDescription,
  LogEventDetailKey,
} from '@console/services/aws/arm/api.models';

import { computed } from 'vue';

const props = defineProps<{ eventLog: ScheduleModificationMessage }>();

const details = computed(() => {
  // Object.entries does not type the key correctly so we are casting it at the
  // very end when we return the flatmap of items
  return Object.entries(props.eventLog.details ?? {}).flatMap(([key, detail]): {
    key: LogEventDetailKey;
    detail: {
      previous_value: string;
      value: string;
      errors?: ErrorDescription[];
    };
  } => {
    return {
      key: key as LogEventDetailKey,
      detail,
    };
  });
});

const errorDescriptionMap: Record<ErrorDescription, string> = {
  InvalidTimezone: 'Timezone is not valid',
  InvalidSchedule: 'Schedule is not valid',
  ConflictingSchedules: 'Start and Stop schedules cannot overlap',
  HibernationWrongValue: "Hibernation must be either 'true' or 'false'",
  HibernationNotSupported: 'Hibernation tag is set but not supported on this instance',
  InvalidEmail: 'Email is not valid',
};

function getErrorDescription(tagKey: LogEventDetailKey, error: ErrorDescription): string {
  if (tagKey === 'prosperops-schedule-start') {
    return 'Start schedule is not valid';
  }

  if (tagKey === 'prosperops-schedule-stop') {
    return 'Stop schedule is not valid';
  }

  return errorDescriptionMap[error];
}
</script>

<template>
  <div class="scheduleUpdateToolTip">
    <div v-for="(d, idx) in details" :key="d.key" :class="{ 'mt-4': idx > 0 }">
      <div class="detailKey">
        <strong>{{ d.key }}</strong>
      </div>

      <div class="mt-2 mb-2">
        <div class="mb-1">
          <div><strong>Previous</strong></div>
          <div>{{ d.detail.previous_value }}</div>
        </div>

        <div>
          <div><strong>Current</strong></div>
          <div>{{ d.detail.value }}</div>
        </div>
      </div>

      <div v-if="d.detail.errors && d.detail.errors.length > 0">
        <div><strong>Errors</strong></div>
        <div v-for="error in d.detail.errors" :key="error" class="ml-1">- {{ getErrorDescription(d.key, error) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.scheduleUpdateToolTip {
  word-break: break-all;
}
.detailKey {
  font-size: 15px;
}
</style>
