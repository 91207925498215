<script lang="ts" setup>
import type { ReallocationMethodType } from '@aws/components/adm/showback/ReallocationMethod.vue';
import type { SavingsShareType } from '@aws/components/adm/showback/SavingsShare.vue';
import type { SelectedServiceType } from '@aws/components/adm/showback/SelectedService.vue';
import type { ShowbackDashboardResponse, ShowbackSummaryRow } from '@aws/components/adm/showback/showback.models';
import type { OrganizationSettingsSummary } from '@console/services/api.models';
import type { Timeframe } from '@shared/design/TimeframeDropdown.vue';
import type { AxiosResponse } from 'axios';

import { useHead } from '@unhead/vue';
import _ from 'lodash';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { allNonComputeServices } from '@aws/components/adm/showback/constants';
import csv from '@console/lib/csvBuilder';
import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import ShowbackTableAccountRow from '@aws/components/adm/showback/ShowbackTableAccountRow.vue';
import ShowbackTableFilter from '@aws/components/adm/showback/ShowbackTableFilter.vue';
import ShowbackTableHeader from '@aws/components/adm/showback/ShowbackTableHeader.vue';
import ShowbackTableSavingsShareRow from '@aws/components/adm/showback/ShowbackTableSavingsShareRow.vue';
import ShowbackTableSummaryRow from '@aws/components/adm/showback/ShowbackTableSummaryRow.vue';
import TimeframeSelector from '@aws/components/adm/showback/TimeframeSelector.vue';
import BuildingState from '@console/components/BuildingState.vue';
import PageLayout from '@console/PageLayout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import BoxMessageV2 from '@shared/design/BoxMessageV2.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';

useHead({
  title: 'Showback',
});

const isLoading = ref(true);
const selectedTimeframe = ref<Timeframe | null>(null);
const showback = ref<ShowbackDashboardResponse | null>(null);
const showbackNotFound = ref(false);

const router = useRouter();
const route = useRoute();
const filters = reactive({
  reallocation_method: 'shared' as ReallocationMethodType,
  savings_share: ((route.query.savings_share as string) || 'include') as SavingsShareType,
  service: ((route.query.service as string) || 'all') as SelectedServiceType,
});

watch(
  filters,
  async () => {
    const newQuery = {
      ...route.query,
      savings_share: filters.savings_share,
      service: filters.service,
    };

    await router.replace({ query: newQuery });
  },
  { deep: true }
);

const vuexStore = useVuexStore();
const isReseller = computed(() => vuexStore.getters['customer/isReseller']);
const isDemo = computed(() => vuexStore.getters['customer/isDemo']);
const orgSettings = computed<OrganizationSettingsSummary>(() => vuexStore.getters['aws/selectedOrganizationSettings']);

onMounted(async () => {
  if (isReseller.value) {
    if (!orgSettings.value?.adm_reseller_showback_dashboard_enabled) {
      await router.push({ name: '404' });
    }
  }

  if (isDemo.value) {
    filters.savings_share = 'exclude';
  }

  await load(route.params.timeframe as string);
});
watch(
  () => route.params.timeframe,
  async timeframe => await load(timeframe as string)
);

const selectedOrganization = computed(() => vuexStore.getters['aws/selectedOrganization']);
const selectedOrganizationManagementAccount = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']
);
const selectedOrganizationAccounts = computed(() => vuexStore.getters['aws/selectedOrganizationAccounts']);

const awsAccountsByNumber = computed(() => {
  var field = isDemo.value ? 'unmasked_aws_account_number' : 'aws_account_number';
  return _.keyBy(selectedOrganizationAccounts.value, field);
});

const availableServices = computed<SelectedServiceType[]>(() => {
  const rows = _.get(showback.value, ['dashboard', 'summaries', 0, 'rows'], []);
  const allExistingServices = _.uniq(_.map(rows, 'service'));
  // always include all and compute
  const services: SelectedServiceType[] = ['all', 'compute'];

  for (const service of AwsServiceHelpers.nonComputeServices) {
    if (_.some(allExistingServices, s => s.includes(AwsServiceHelpers.getDisplayName(service)))) {
      services.push(service);
    }
  }

  return services;
});

interface EnhancedShowbackSummaryRow extends ShowbackSummaryRow {
  original_cost_rounded: number;
  original_savings_rounded: number;
  undiscounted_usage_rounded: number;
  reallocated_savings_rounded: number;
  adjusted_cost_rounded: number;
  net_adjustment_rounded: number;
}

const showbackRows = computed<EnhancedShowbackSummaryRow[]>(() => {
  const round = (row: ShowbackSummaryRow, key: keyof ShowbackSummaryRow) =>
    Number(((_.get(row, key, 0) as number) || 0).toFixed(2));

  const summaries = _.get(showback.value, ['dashboard', 'summaries'], []);
  const reallocationMethod = filters.reallocation_method;
  const savingsShare = filters.savings_share;
  const filter = {
    reallocation_method: _.capitalize(reallocationMethod),
    includes_savings_share: _.toLower(savingsShare) === 'include',
  };
  const summary = _.find(summaries, filter);
  const rows = filterBySelectedService(_.get(summary, 'rows', []));

  return rows.map(row => ({
    ...row,
    original_cost_rounded: round(row, 'original_cost'),
    original_savings_rounded: round(row, 'original_savings'),
    undiscounted_usage_rounded: round(row, 'undiscounted_usage'),
    reallocated_savings_rounded: round(row, 'reallocated_savings'),
    adjusted_cost_rounded: round(row, 'adjusted_cost'),
    net_adjustment_rounded: round(row, 'net_adjustment'),
    service: row.service === 'ProsperOps Savings Share' ? 'ProsperOps Compute Savings Share' : row.service,
  }));
});

const awsAccountRows = computed(() => {
  const awsAccountRows = _.filter(showbackRows.value, row => !!row.aws_account_number);
  return sortShowbackRows(awsAccountRows);
});

const showSavingsShareRows = computed(() => !!_.find(showbackRows.value, row => !row.aws_account_number));

const savingsShareRows = computed(() => {
  const rows = _.filter(showbackRows.value, row => !row.aws_account_number);
  const totalNetAdjustment = _.sumBy(rows, 'net_adjustment');
  return {
    aws_account_number: '',
    friendly_name: 'Prosper Ops Savings Share',
    rows,
    total_net_adjustment_rounded: totalNetAdjustment,
  };
});

const filterKey = computed(() => {
  const selectedService = filters.service;
  const reallocationMethod = filters.reallocation_method;
  const savingsShare = filters.savings_share;
  return `showback_filter_${selectedService}_${reallocationMethod}_${savingsShare}`;
});

async function load(timeframe: string) {
  try {
    isLoading.value = true;
    const response = (await customerService.getShowback(
      selectedOrganization.value.id,
      timeframe
    )) as AxiosResponse<ShowbackDashboardResponse>;

    showback.value = response.data;
    selectedTimeframe.value = showback.value.selected_timeframe;
    showbackNotFound.value = false;
  } catch (e) {
    const status = _.get(e, 'response.status', 500) as number;
    if (status === 404) {
      showbackNotFound.value = true;
    } else {
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
}

async function onChange(nextTimeframe: Timeframe) {
  const timeframe = nextTimeframe.key;
  await router.push({ name: 'aws_showback', params: { timeframe }, query: route.query });
}

function downloadCSV() {
  const filename = csv.showbackFileName(selectedTimeframe.value, selectedOrganizationManagementAccount.value, filters);

  let sorted = _.orderBy(showbackRows.value, ['aws_account_number'], ['asc']);

  sorted = _.map(sorted, row =>
    _.assign({}, row, {
      aws_account_number: !row.aws_account_number ? '' : awsAccountNumberForRow(row.aws_account_number),
      friendly_name: !row.aws_account_number ? '' : awsAccountFriendlyNameForRow(row.aws_account_number),
    })
  );

  const rows = csv.showbackRows(sorted);
  csv.saveFile(filename, rows);
}

function awsAccountNumberForRow(awsAccountNumber: string) {
  // Deleted accounts won't show up in the store, and will need censored manually
  const awsAccount = awsAccountsByNumber.value[awsAccountNumber];
  const fallback = isDemo.value ? 'XXXXXXXXXXXX' : awsAccountNumber;
  return _.get(awsAccount, 'aws_account_number', fallback);
}

function awsAccountFriendlyNameForRow(awsAccountNumber: string) {
  // Deleted accounts won't show up in the store, and will need censored manually
  const awsAccount = awsAccountsByNumber.value[awsAccountNumber];
  return _.get(awsAccount, 'friendly_name', 'AWS Account');
}

function sortShowbackRows(awsAccountRows: EnhancedShowbackSummaryRow[]) {
  // accounts with net_adjustment < 0 are sorted first (asc),
  // then, we add accounts with net_adjustment >= 0, which are sorted (asc)
  // by friendly name.
  const groups = _.groupBy(awsAccountRows, 'aws_account_number');
  const sortable = _.map(groups, (rows, key) => ({
    rows,
    aws_account_number: awsAccountNumberForRow(key),
    friendly_name: awsAccountFriendlyNameForRow(key),
    total_net_adjustment_rounded: _.sumBy(rows, 'net_adjustment_rounded'),
  }));
  const isNegativeNetAdjustment = (a: typeof sortable[0]) => a.total_net_adjustment_rounded < 0;
  const lowerCaseFriendlyName = (a: typeof sortable[0]) => a.friendly_name.toLowerCase();
  let [negative, nonnegative] = _.partition(sortable, isNegativeNetAdjustment);
  negative = _.orderBy(negative, ['total_net_adjustment_rounded'], ['asc']);
  nonnegative = _.orderBy(nonnegative, [lowerCaseFriendlyName], ['asc']);
  return _.concat(negative, nonnegative);
}

function filterBySelectedService(rows: ShowbackSummaryRow[]) {
  const serviceFilter = AwsServiceHelpers.getDisplayName(filters.service);
  if (serviceFilter === 'All') {
    return rows;
  }

  if (serviceFilter === 'Compute') {
    return _.filter(rows, row => !allNonComputeServices.includes(row.service));
  }

  return _.filter(rows, row => row.service.includes(serviceFilter));
}
</script>

<template>
  <PageLayout :loading="isLoading">
    <template #default>
      <BuildingState v-if="showbackNotFound" variant="showback" />
      <div v-else-if="selectedTimeframe && showback">
        <div class="showbackViewDisabled">
          <p class="lead text-center mb-0 mt-5">Showback is not supported on your device's screen resolution.</p>
        </div>
        <div class="showback">
          <PageHeader wrap-utility>
            <h1>Showback</h1>
            <template v-slot:utility>
              <TimeframeSelector
                :selected="selectedTimeframe"
                :timeframes="showback.available_timeframes"
                @change="onChange"
              />
            </template>
          </PageHeader>
          <div class="row sectional">
            <div class="col">
              <BoxMessageV2>
                <div>
                  <p>
                    Native AWS billing does not properly allocate savings generated from a centralized portfolio of
                    Reserved Instances and Savings Plans out to an Organization. This creates transparency and
                    cost/savings attribution problems. This report addresses that by properly reallocating savings and
                    adjusting cost on a per resource basis.
                    <a href="https://help.prosperops.com/showback-intro" target="_blank">Learn More</a>
                  </p>
                  <p class="mb-0">
                    Note: Amounts shown only include AWS commitment discountable services and do not include
                    post-purchase adjustments such as AWS Private Pricing, EDPs, refunds, credits, taxes, etc.
                  </p>
                </div>
              </BoxMessageV2>
            </div>
          </div>
          <div v-if="isReseller" class="row sectional">
            <div class="col">
              <BoxMessage type="warning">
                Amounts shown include End Customer commitments and may not match the Arbitrage amounts reported on the
                Savings Dashboard.
              </BoxMessage>
            </div>
          </div>
          <div v-if="!showback.customer_visible" class="row sectional">
            <div class="col">
              <BoxMessage type="error">
                <div>
                  This month is not currently customer visible as either: a) the net adjustment is not $0 or b) one or
                  more rows have reallocated savings that exceeds undiscounted usage.
                </div>
              </BoxMessage>
            </div>
          </div>
          <div class="row sectional">
            <div class="col">
              <Panel class="pt-0">
                <div class="pt-3 pb-3 showbackStickyHeader">
                  <ShowbackTableFilter
                    v-model:reallocation-method="filters.reallocation_method"
                    v-model:savings-share="filters.savings_share"
                    v-model:selected-service="filters.service"
                    :demo="isDemo"
                    :enabled-services="availableServices"
                    @download-csv="downloadCSV"
                  />
                  <div class="pt-2">
                    <ShowbackTableHeader />
                  </div>
                </div>
                <div>
                  <div :key="filterKey" class="fadeIn">
                    <ShowbackTableSummaryRow :rows="showbackRows" />
                    <ShowbackTableAccountRow
                      v-for="row in awsAccountRows"
                      :key="row.aws_account_number"
                      class="resourceRow"
                      :row="row"
                    />
                    <ShowbackTableSavingsShareRow
                      v-if="showSavingsShareRows"
                      class="resourceRow"
                      :row="savingsShareRows"
                    />
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss" scoped>
.showback {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
}

.showbackViewDisabled {
  display: block;

  @media (min-width: 1200px) {
    display: none;
  }
}

.showbackStickyHeader {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;
}

.resourceRow:not(:last-child) {
  border-bottom: 2px solid #f5f6fa;
}

.fadeIn {
  opacity: 1;
  animation: fade 0.25s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
