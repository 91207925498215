import type { AzureTenantStatus, } from '@azure/services/subscriptions';
import type { BootstrapVariant } from '@shared/design/bootstrap';


const statusMappings: Record<AzureTenantStatus, { variant: BootstrapVariant; label: string }> = {

  // Show all Provisioning statuses as Provisioning to simplify things for the user
  Provisioning: { variant: 'info', label: 'Provisioning' },
  ProvisioningError: { variant: 'info', label: 'Provisioning' },

  AccessError: { variant: 'danger', label: 'Access Error' },
  Active: { variant: 'success', label: 'Active' },
} as const;


const getStatusLabel = (status: AzureTenantStatus) => statusMappings[status].label ?? status;
const getStatusVariant = (status: AzureTenantStatus) => statusMappings[status].variant ?? 'primary';



export default {
  getStatusLabel,
  getStatusVariant
};
