<template>
  <p class="text-muted settingDescription">
    <slot />
  </p>
</template>

<style lang="scss" scoped>
.settingDescription {
  font-size: 0.85rem;
}
</style>
