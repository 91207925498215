<script setup>
import Percentage from '@shared/components/Percentage.vue';
import UtilizationIcon from '@shared/design/icons/UtilizationIcon.vue';
import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const props = defineProps({
  utilization: {
    type: Number,
    required: true,
  },
  hasReservations: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <MetricPanel name="Reservation Utilization" color="primary" :icon="UtilizationIcon">
    <Percentage :value="props.utilization" :decimals="props.utilization === 1 ? 0 : 1" :convert-to-percent="true" />
  </MetricPanel>
</template>
