<script setup lang="ts">
import type { ColDef, GridApi, ICellRendererParams, ITooltipParams } from '@ag-grid-community/core';
import type {
  EventLog,
  PowerActionEventLog,
  EventLogMessage,
  ScheduleModificationMessage,
  PowerActionEventLogMessage,
} from '@console/services/aws/arm/api.models';
import type { Ref } from 'vue';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';

import { getEventLogs } from '@aws/services/awsArmService';
import { useVuexStore } from '@console/state/vuex/store';

import StatusCell from '@aws/components/arm/event-logs/StatusCell.vue';
import PowerActionsChart from '@aws/views/arm/scheduler/PowerActionsChart.vue';
import TimePicker from '@aws/views/arm/scheduler/TimePicker.vue';
import PageLayout from '@console/PageLayout.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

useHead({
  title: 'Event Log',
});

interface Timeframe {
  value: number;
  label: string;
}

const store = useVuexStore();
const awsOrgId = computed(() => store.getters['aws/selectedOrganizationId']);
const defaultSinceDays = 7;
const sinceDays = ref(defaultSinceDays);
const isLoading = ref(true);
const isGridLoading = ref(true);
const hasEventLogs = ref(false);
const hasFilteredPowerActions = ref(false);
const powerActionEventsHeader = ref('Power Action Events');
const gridApi: Ref<GridApi | null> = ref(null);

// Api data consumed by ag-grid
const rowData = ref<EventLog[]>([]);

// Visible data in the grid. At load this will be everything, and then filtered by the user
const powerActionChartData: Ref<PowerActionEventLog[]> = ref([]);

const colDefs = ref<Array<ColDef<EventLog>>>([
  {
    headerName: 'Time',
    filter: true,
    cellRenderer: createLocalTimeCellValue,
    tooltipValueGetter: createUtcTimeTooltip,
  },
  { headerName: 'Instance ID', valueGetter: i => i.data?.instance_id, filter: true, tooltipField: 'instance_id' },
  { headerName: 'Name', valueGetter: i => i.data?.display_name, filter: true, tooltipField: 'display_name' },
  {
    headerName: 'Account',
    valueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`,
    filter: true,
    tooltipValueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`,
  },
  { headerName: 'Region', valueGetter: i => i.data?.region, filter: true, tooltipField: 'region' },
  {
    headerName: 'Event Type',
    valueGetter: params => calculateEventType(params),
    cellRenderer: calculateEventType,
    tooltipValueGetter: params => calculateEventType(params),
    filter: true,
  },
  {
    headerName: 'Status',
    filter: true,
    filterValueGetter: statusMessage,
    cellRenderer: StatusCell,
    cellRendererParams: (params: ICellRendererParams) => ({
      statusMessage: statusMessage(params),
      originalLogMessage: params.data.message,
    }),
    comparator: (_, __, nodeA: { data?: EventLog }, nodeB: { data?: EventLog }) => {
      const a = statusMessage(nodeA);
      const b = statusMessage(nodeB);

      if (a === b) return 0;
      return a > b ? 1 : -1;
    },
  },
]);

function createLocalTimeCellValue(params: ICellRendererParams<EventLog>) {
  const createdDate = new Date(params.data?.created_date || '');
  if (!createdDate) {
    return '(No date available)';
  }
  return formatEventLogDate(createdDate, false);
}

function createUtcTimeTooltip(params: ITooltipParams<EventLog>) {
  const createdDate = new Date(params.data?.created_date || '');

  if (!createdDate) {
    return 'UTC: (No date available)';
  }

  return formatEventLogDate(createdDate, true);
}

function formatEventLogDate(date: Date, useUtc: boolean): string {
  const datePart = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: useUtc ? 'UTC' : undefined,
  });

  const timePart = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: useUtc ? 'UTC' : undefined,
  });

  const formattedDate = `${datePart} ${timePart}`;
  return useUtc ? `UTC: ${formattedDate}` : formattedDate;
}

const timepickerOptions = [
  { value: 1, label: '24 hours' },
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 90, label: '90 days' },
];

const onGridReady = (params: { api: GridApi }) => {
  gridApi.value = params.api;
  getFilteredPowerActionData();
  isGridLoading.value = false;
};

const onFilterChanged = () => {
  getFilteredPowerActionData();

  // Detect if filters are applied and update header accordingly
  if (gridApi.value?.isAnyFilterPresent()) {
    powerActionEventsHeader.value = 'Power Action Events (Filtered)';
  } else {
    powerActionEventsHeader.value = 'Power Action Events';
  }
};

const getFilteredPowerActionData = () => {
  if (!gridApi.value) return;

  // Get the visible chart data post filtering
  const visibleRowData: PowerActionEventLog[] = [];
  gridApi.value.forEachNodeAfterFilter(node => {
    visibleRowData.push(node.data);
  });

  // Further reduce visible chart data down to just power action events
  const filteredPowerActionEvents = getPowerActionEvents(visibleRowData);
  hasFilteredPowerActions.value = filteredPowerActionEvents.length > 0;
  powerActionChartData.value = filteredPowerActionEvents;
};

const getPowerActionEvents = (events: PowerActionEventLog[]) => {
  return events.filter(
    event =>
      event.message?.event_type === 'PowerAction' || event.message?.event_type === 'ExternalPowerActionObservation'
  );
};

function statusMessage(params: { data?: { message: EventLogMessage } }): string {
  const message = params.data?.message;

  if (!message) return 'Unknown';

  if (message.event_type === 'ScheduleUpdated') {
    const error = (message as ScheduleModificationMessage).error_severity;
    if (error === 'Error') {
      return 'Failed';
    } else {
      return 'Succeeded';
    }
  }

  if (message.event_type === 'PowerAction') {
    return (message as PowerActionEventLogMessage).action_outcome;
  }

  if (message.event_type === 'ExternalPowerActionObservation') {
    return 'External';
  }

  return 'Unknown';
}

function calculateEventType(params: { data?: { message?: EventLogMessage } }) {
  switch (params.data?.message?.activity_type) {
    case 'ScheduleAdded':
      return 'Schedule Added';
    case 'ScheduleRemoved':
      return 'Schedule Removed';
    case 'ScheduleUpdated':
      return 'Schedule Updated';
    case 'PowerActionOn':
      return 'Start';
    case 'PowerActionOff':
      return 'Stop';
    case 'InstanceTerminated':
      return 'Instance Terminated';
  }
}

onMounted(async () => {
  isLoading.value = true;
  rowData.value = await getEventLogs(awsOrgId.value, sinceDays.value);
  hasEventLogs.value = rowData.value.length > 0;
  isLoading.value = false;
});

const handleTimepickerChange = async (timeframe: Timeframe) => {
  isGridLoading.value = true;
  const since = timeframe.value;
  isLoading.value = true;
  rowData.value = await getEventLogs(awsOrgId.value, since);
  hasEventLogs.value = rowData.value.length > 0;
  sinceDays.value = since;
  isLoading.value = false;
};
</script>

<template>
  <PageHeader wrap-utility>
    <div class="d-flex gap-3 align-items-center mr-2">
      <h1>Event Log</h1>
    </div>
    <template v-slot:utility>
      <TimePicker
        :options="timepickerOptions"
        :default-option-index="1"
        @on-change="handleTimepickerChange"
      ></TimePicker>
    </template>
  </PageHeader>
  <PageLayout v-if="hasEventLogs" :loading="isLoading">
    <template #default>
      <div class="row sectional">
        <div class="col">
          <PanelSection v-if="!isGridLoading && hasFilteredPowerActions" :header="powerActionEventsHeader">
            <PowerActionsChart :power-action-data="powerActionChartData" :since-days="sinceDays"></PowerActionsChart>
          </PanelSection>
          <PanelSection v-else :header="powerActionEventsHeader">
            <div class="noFilteredDataContainer">
              <section class="centerContent">
                <div class="noDataMessage">
                  No chart data found. Update your filters or expand your search timeframe.
                </div>
              </section>
            </div>
          </PanelSection>
        </div>
      </div>

      <AgGridVue
        :row-data="rowData"
        :column-defs="colDefs"
        suppress-movable-columns
        :auto-size-strategy="{ type: 'fitGridWidth' }"
        :modules="[ClientSideRowModelModule]"
        :tooltip-show-delay="0"
        tooltip-show-mode="whenTruncated"
        :ensure-dom-order="true"
        style="width: 100%; height: 60%"
        class="ag-theme-quartz"
        enable-cell-text-selection
        @grid-ready="onGridReady"
        @filter-changed="onFilterChanged"
      />
    </template>
  </PageLayout>
  <div v-else class="noDataContainer">
    <div class="panel rounded-sm panelSection">
      <section class="centerContent">
        <div class="noDataMessage">
          No event log data found for this selection. Visit our docs to start saving money with usage optimization.
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.noFilteredDataContainer {
  height: 252px; // Exact to match chart + chart header height
  border: 1px dashed lightgray;
  border-radius: 5px;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  text-align: center;
}

.noDataMessage {
  padding: 15px;
  font-size: 1.0925rem;
  font-weight: 500;
  color: #555;
}

.panel {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);

  @include media-breakpoint-up(lg) {
    // When displaying side by side with the savings breakdown, make sure there's enough horizontal space to prevent
    // the text from wrapping
    min-width: 428px;
  }
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
</style>
