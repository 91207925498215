<script setup lang="ts">
import type { ColDef } from '@ag-grid-community/core';
import type { SubscriptionResponse, Tenant } from '@azure/services/subscriptions';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { ref } from 'vue';

import AzureAccountStatusPill from '@azure/components/tenants/AzureAccountStatusPill.vue';
import CollapsePanel from '@shared/design/panels/CollapsePanel.vue';

const props = defineProps<{
  validateTenant: (tenant: Tenant) => void;
  validateAll: () => void;
  tenants: Tenant[];
}>();

const colDefs = ref(<Array<ColDef<SubscriptionResponse>>>[
  {
    colId: 'display_name',
    headerName: 'Subscription Name',
    valueGetter: i => i.data?.display_name,
    filter: true,
    resizable: false,
  },
  {
    colId: 'vendor_id',
    headerName: 'Subscription ID',
    valueGetter: i => i.data?.vendor_id,
    filter: true,
    resizable: false,
  },
  {
    coId: 'status',
    headerName: 'Status',
    valueGetter: i => i.data?.status,
    filter: true,
    resizable: false,
  },
]);
</script>
<template>
  <div class="row mt-2 mb-2">
    <div class="col">
      <ul class="azureAccountList list-group pt-0 rounded-sm">
        <li v-for="tenant in props.tenants" :key="tenant.tenant_id" class="list-group-item azureAccountListItem">
          <CollapsePanel class="mb-3 py-2 azureTenantList">
            <template #header>
              <div class="azureAccountRow">
                <div class="status">
                  <AzureAccountStatusPill :status="tenant.validated ? 'Active' : 'AccessError'" />
                </div>
                <div class="detail">
                  <div class="description">
                    <div>
                      <span>Tenant ID: {{ tenant.tenant_id }}</span>
                    </div>
                    <div class="header d-flex align-items-center column-gap-1">
                      <b>{{ tenant.subscriptions.length }} Subscriptions</b>
                    </div>
                  </div>
                </div>
                <div class="actions">
                  <FormSubmitButton
                    v-if="!tenant.validated"
                    variant="primary"
                    class="rounded-sm mr-2"
                    size="sm"
                    :loading="false"
                    :disabled="false"
                    @click.prevent="validateTenant(tenant)"
                  >
                    Validate Access
                    <template v-slot:loading> Validating... </template>
                  </FormSubmitButton>
                  <RouterLink
                    class="btn btn-dark btn-sm mr-2"
                    :to="{ name: 'azure_tenant', params: { tenantId: tenant.tenant_id } }"
                    @click="(e: Event) => e.stopPropagation()"
                  >
                    View Configuration
                  </RouterLink>
                </div>
              </div>
            </template>
            <template #default>
              <div class="contents">
                <div class="row mt-2 mb-2">
                  <div class="col">
                    <AgGridVue
                      :row-data="tenant.subscriptions"
                      :column-defs="colDefs"
                      suppress-movable-columns
                      :modules="[ClientSideRowModelModule]"
                      :ensure-dom-order="true"
                      :tooltip-show-delay="1000"
                      tooltip-show-mode="whenTruncated"
                      class="ag-theme-quartz"
                      style="width: 100%; height: 325px"
                      enable-cell-text-selection
                      :pagination="true"
                      :pagination-page-size="5"
                      :pagination-page-size-selector="false"
                      :auto-size-strategy="{
                        type: 'fitGridWidth',
                      }"
                    />
                  </div>
                </div>
              </div>
            </template>
          </CollapsePanel>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.azureAccountList {
  padding: 0;
  padding-top: 0.5rem;
  list-style-type: none;
}

.azureAccountList li {
  margin-bottom: 0;
  box-shadow: 0 0 3px -3px rgba(0, 0, 0, 0);
}

.azureAccountRow {
  display: flex;
}

.panel.azureTenantList {
  box-shadow: 0 0 3px -3px rgba(0, 0, 0, 0);
}

.content {
  border-top: 0 none rgba(0, 0, 0, 0);
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.description {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div:first-child {
    padding-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
  }

  > div:last-child {
    padding-bottom: 0.25rem;
    font-size: 0.8rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  min-width: 220px;
}

.failureInfo {
  margin-left: 5px;
  cursor: pointer;
}

.actionFailures {
  width: 100%;
  table-layout: fixed;

  > thead {
    display: block;
  }

  > thead > tr {
    display: inline-block;
    width: 100%;
  }

  > tbody {
    display: block;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }

  > tbody::-webkit-scrollbar-track {
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar {
    width: 16px;
    background-color: #fff;
  }

  > tbody::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border: 4px solid #fff;
    border-radius: 16px;
  }

  > tbody > tr {
    display: inline;
  }

  > tbody > tr > td {
    padding-bottom: 10px;
  }
}

.failureAction {
  display: inline-block;
  width: 35%;
  text-align: left;
}

.failureRegion {
  display: inline-block;
  width: 15%;
  text-align: left;
}

.failureError {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  > span {
    padding-right: 5px;
  }
}

.azureAccountListPill {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  vertical-align: text-top !important;
}

// set breakpoint one larger than .layoutSidebar to ensure
// wrapping styles trigger once main content section narrows
@include media-breakpoint-up(lg) {
  .detail {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .actions {
    justify-content: right;
  }
}

.header {
  flex-wrap: wrap;
  cursor: pointer;

  .badge {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    font-size: 0.8rem;
  }
}

.offerings {
  margin-left: -1.5rem;

  > li {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contents ul {
  padding-left: 0.5rem;

  li {
    padding-left: 1rem;
    list-style: none;
  }
}
</style>
