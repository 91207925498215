<script setup lang="ts">
import type { PowerActionEventLogMessage } from '@console/services/aws/arm/api.models';

import { computed } from 'vue';
const props = defineProps<{ eventLog: PowerActionEventLogMessage }>();

const tooltipTextForPowerAction = computed(() => {
  return props.eventLog.action_outcome === 'Failed' ? 'Error message received' : 'Unspecified Error';
});
</script>

<template>
  <div>
    <div class="header"><strong>Power state change failure</strong></div>
    <div>{{ tooltipTextForPowerAction }}</div>
  </div>
</template>

<style scoped lang="scss">
.header {
  font-size: 15px;
}
</style>
