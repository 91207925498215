<script setup lang="ts">
import type { Ec2InventorySchedule, OnOffDatapoints } from '@console/services/aws/arm/api.models';

import { ref } from 'vue';

import { getEc2InventorySchedule } from '@aws/services/awsArmService';

import LoadingState from '@shared/components/LoadingState.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import Modal from '@shared/design/modals/Modal.vue';

const props = defineProps<{
  params: {
    inventoryId: string;
    awsOrgId: string;
    title: string;
  };
}>();

const showScheduleModal = ref(false);
const isLoading = ref(true);
const scheduleData = ref<Ec2InventorySchedule | null>(null);
const hourlyIntervals = ref(
  Array.from({ length: 24 }, (_, i) => {
    return String(i).padStart(2, '0');
  })
);
const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;

async function onShow() {
  isLoading.value = true;
  showScheduleModal.value = true;
  scheduleData.value = await getEc2InventorySchedule(props.params.awsOrgId, props.params.inventoryId);
  isLoading.value = false;
}

// Grabs the four 15-minute slices for a given day and hourly interval
function getFifteenMinuteSlice(day: keyof OnOffDatapoints, hourlyIntervalIdx: number): boolean[] {
  const start = hourlyIntervalIdx * 4;
  const end = start + 4;
  return scheduleData.value?.on_off_datapoints[day].slice(start, end) ?? [];
}
</script>

<template>
  <div style="display: flex; align-items: center; justify-content: center">
    <div style="width: 40px; text-align: center; cursor: pointer" @click="onShow">
      <BaseIcon style="width: 25px; height: 25px; vertical-align: middle" name="calendar-alt" variant="far" />
    </div>
  </div>
  <Modal v-model="showScheduleModal" :title="props.params.title" size="xl">
    <LoadingState v-if="isLoading" />
    <div v-else>
      <div v-if="scheduleData" class="awsArmScheduleTableGraphic">
        <div class="aastgColName"></div>

        <div v-for="hourlyInterval in hourlyIntervals" :key="hourlyInterval" class="aastgCellBorder aastgIntervalName">
          {{ hourlyInterval }}
        </div>

        <template v-for="day in days" :key="day">
          <div class="aastgColName">{{ day }}</div>

          <div
            v-for="(hourlyInterval, hourlyIntervalIdx) in hourlyIntervals"
            :key="hourlyInterval"
            class="aastgCellBorder aastgCell"
          >
            <div v-if="scheduleData.use_fifteen_minute_granularity">
              <div
                v-for="(isOn, index) in getFifteenMinuteSlice(day, hourlyIntervalIdx)"
                :key="`${day}-${index}`"
                class="aastgFifteenMinuteCell"
                :class="{ aastgCellOn: isOn, aastgCellOff: !isOn }"
              ></div>
            </div>
            <div
              v-else
              :class="{
                aastgCellOn: scheduleData.on_off_datapoints[day][hourlyIntervalIdx],
                aastgCellOff: !scheduleData.on_off_datapoints[day][hourlyIntervalIdx],
              }"
            ></div>
          </div>
        </template>
      </div>
      <div class="aastgInfo container">
        <div class="row mb-1">
          <div class="font-weight-bold col-2">Hours up</div>
          <div class="col">{{ scheduleData?.hours_up }}</div>
        </div>
        <div class="row mb-1">
          <div class="font-weight-bold col-2">Hours down</div>
          <div class="col">{{ scheduleData?.hours_down }}</div>
        </div>
        <div class="row mb-1">
          <div class="font-weight-bold col-2">Start schedule tag</div>
          <div class="col">{{ scheduleData?.start_schedule_raw }}</div>
        </div>
        <div class="row mb-1">
          <div class="font-weight-bold col-2">Stop schedule tag</div>
          <div class="col">{{ scheduleData?.stop_schedule_raw }}</div>
        </div>
        <div class="row mb-1">
          <div class="font-weight-bold col-2">Timezone tag</div>
          <div class="col">{{ scheduleData?.timezone_raw }}</div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
// these styles can't be scoped due to how ag-grid handles rendering the vue component
@import '@shared/scss/colors.scss';

.awsArmScheduleTableGraphic {
  display: grid;
  grid-template-rows: auto repeat(8, 1fr);
  grid-template-columns: auto repeat(24, 1fr);
  width: 100%;
  overflow: auto;
  font-size: 14px;

  .aastgIntervalName {
    padding: 3px;
    text-align: center;
  }

  .aastgColName {
    padding-right: 3px;
    text-transform: capitalize;
  }

  .aastgCellBorder {
    border: 1px solid white;
  }

  .aastgCellOn {
    background-color: map-get($theme-colors, 'success');
  }

  .aastgCellOff {
    background-color: map-get($theme-colors, 'danger');
  }

  .aastgCell {
    div {
      width: 100%;
      height: 100%;
      min-height: 1px;
    }

    .aastgFifteenMinuteCell {
      display: inline-block;
      width: 25%;
    }
  }
}

.aastgInfo {
  &.container {
    padding-left: 0;
  }
}
</style>
