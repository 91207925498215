<script setup lang="ts">
import type { AwsAdmOfferingCode, BillingOfferingVariant } from '@console/services/api.models';
import type {
  ComputeBaseFlexSpendCoverageTrend,
  ComputeInheritedBaseSmartSpendCoverageTrend,
  ComputeSpendCoverageTrend,
  NonComputeSpendCoverageTrend,
  SpendCoverageTrend,
} from '@console/services/aws/savings.models';
import type Highcharts from 'highcharts';
import type { TooltipFormatterContextObject, TooltipOptions } from 'highcharts';

import Big from 'big.js';
import he from 'he';
import moment from 'moment';
import { computed, onMounted, ref } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import NumberHelpers from '@shared/utilities/number_helpers';
import { isTruthy } from '@shared/utilities/typescript_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const SERIES_NAME_BASE_TARGET = 'Base Target';

interface Series extends Highcharts.SeriesOptions {
  legendOrder: number;
  legendSelected: boolean;
}

type SeriesColors = Partial<Record<keyof SpendCoverageTrend, string>>;
const seriesColors: {
  compute: Partial<Record<BillingOfferingVariant, SeriesColors>>;
  nonCompute: SeriesColors;
} = {
  compute: {
    AwsComputeBaseFlex: {
      capacity_block_reservation_usage: '#fecbcb',
      on_demand: '#fc5454',
      spot: '#fcbe2c',
      flex: '#00c58c',
      flex_boost: '#8fffdf',
      base_convertible_reserved_instances: '#a7a3ff',
      base_standard_reserved_instances: '#d4d1ff',
      base_ec2_instance_savings_plans: '#adb5bd',
      base_compute_savings_plans: '#5c54ff',
      unbilled: '#dee2e6',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeBaseFlexSpendCoverageTrend, string>,
    AwsComputeInheritedBaseSmart: {
      capacity_block_reservation_usage: '#fecbcb',
      on_demand: '#fc5454',
      spot: '#fcbe2c',
      smart_convertible_reserved_instances: '#8fffdf',
      smart_compute_savings_plans: '#00c58c',
      smart_ec2_instance_savings_plans: '#a7a3ff',
      base_compute_savings_plans: '#5c54ff',
      unbilled: '#dee2e6',
      inherited_convertible_reserved_instances: '#adb5bd',
      inherited_standard_reserved_instances: '#d9fff4',
      inherited_compute_savings_plans: '#d4d1ff',
      inherited_ec2_instance_savings_plans: '#feebbf',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeInheritedBaseSmartSpendCoverageTrend, string>,
  },
  nonCompute: {
    on_demand: '#fc5454',
    smart_reserved_coverage: '#00c58c',
    inherited_reserved_coverage: '#5c54ff',
  } as Record<keyof NonComputeSpendCoverageTrend, string>,
};

type Props = {
  spendCoverageTrend: SpendCoverageTrend[];
  dashboardVariant?: BillingOfferingVariant;
  service: AwsAdmOfferingCode;
  allDiscounts?: boolean;
  emptyYAxisMax?: number;
};

const props = withDefaults(defineProps<Props>(), {
  dashboardVariant: undefined,
  allDiscounts: false,
  emptyYAxisMax: undefined,
});

const noData = ref(false);
const series = ref([] as Series[]);

const isProductCompute = computed(() => props.service === 'compute');
const xAxis = computed(() => ({
  categories: props.spendCoverageTrend.map(sc => moment.utc(sc.month_start).format('MMM YYYY')),
}));

const yAxis = computed<Highcharts.YAxisOptions[]>(() => [
  {
    min: 0,
    max: noData.value ? props.emptyYAxisMax : undefined,
    title: {
      text: null,
    },
    labels: {
      format: chartUtilities.getCurrencyFormat(),
    },
  },
]);

const filteredSeries = computed(() => series.value.filter(s => s.legendSelected));
const serviceDisplayName = computed(() => AwsServiceHelpers.getDisplayName(props.service));
const tooltip = computed<TooltipOptions>(() => {
  return {
    ...chartUtilities.defaultTooltip,
    formatter: function () {
      const categoryIndex = xAxis.value.categories.indexOf(this.x as string);
      const month = months.value[categoryIndex];
      const points = this.points ?? [];
      const xLabel = this.x?.toString() ?? '';

      const excludedPoints = points.filter(r => r.series.name === SERIES_NAME_BASE_TARGET);
      const includedPoints = points.filter(r => r.series.name !== SERIES_NAME_BASE_TARGET);
      const totalValue = Number(includedPoints.reduce((sum, p) => sum.add(p.y ?? 0), Big(0)));
      const excludedRowsHtml = excludedPoints.map(p => pointToHtml(p, month)).join('');
      const includedRowsHtml = includedPoints.map(p => pointToHtml(p, month)).join('');

      let totalHtml = '';
      if (includedPoints.length >= 2) {
        totalHtml = `
          <div style="padding-top: 2px">
            ${chartUtilities.encodedTooltipRow(
              `${serviceDisplayName.value} Usage`,
              totalValue,
              NumberHelpers.formatDollars
            )}
          </div>
          `;
      }

      let overallCoverageHtml = '';
      if (month.overall_spend_coverage_percentage) {
        overallCoverageHtml = chartUtilities.encodedTooltipRow(
          'Overall Spend Coverage',
          month.overall_spend_coverage_percentage,
          v => NumberHelpers.formatNumber(v, 1) + '%'
        );
      }

      let activeOrgsHtml = '';
      if (month.active_organizations && month.active_organizations > 0) {
        activeOrgsHtml = chartUtilities.encodedTooltipRow('Active Organizations', month.active_organizations);
      }

      return `
            <div>
              <div style="font-size: 12px;">
                <strong>${he.encode(xLabel)}</strong>
              </div>
              ${excludedRowsHtml /* excluded rows are shown first */}
              ${includedRowsHtml}
              ${totalHtml}
              ${overallCoverageHtml}
              ${activeOrgsHtml}
            </div>
          `;
    },
  };
});

const pointToHtml = (point: TooltipFormatterContextObject, month: SpendCoverageTrend) => {
  let percentage: number | undefined;
  var seriesDef = isProductCompute.value
    ? computeSeriesDefs.value.find(s => s && s.name === point.series.name)
    : nonComputeSeriesDefs.find(s => s.name === point.series.name);
  if (seriesDef) {
    const percentageField = seriesDef.field + '_percentage';
    if (percentageField in month) {
      percentage = month[percentageField as keyof SpendCoverageTrend] as number;
    }
  }
  return chartUtilities.encodedTooltipRow(
    point.series.name,
    point.y,
    NumberHelpers.formatDollars,
    point.color as string,
    percentage
  );
};

interface SeriesDef {
  name: string;
  type: 'area' | 'line';
}

// eslint-disable-next-line no-undef
type PercentageFields = keyof ExtractFieldsBySuffix<ComputeSpendCoverageTrend, '_percentage'>;
interface ComputeSeriesDef extends SeriesDef {
  // false positive, defined in utility.d.ts
  // eslint-disable-next-line no-undef
  field: keyof OptionalNumericFields<Omit<ComputeSpendCoverageTrend, PercentageFields>>;
}

interface NonComputeSeriesDef extends SeriesDef {
  // false positive, defined in utility.d.ts
  // eslint-disable-next-line no-undef
  field: keyof OptionalNumericFields<NonComputeSpendCoverageTrend>;
}

// This is the order the items will be stacked in the chart (the first will be on the top)
const computeSeriesDefs = computed<(ComputeSeriesDef | false)[]>(() => [
  props.allDiscounts && {
    name: 'Capacity Block Reservation',
    field: 'capacity_block_reservation_usage',
    type: 'area',
  },
  { name: 'On-Demand', field: 'on_demand', type: 'area' },
  props.allDiscounts && { name: 'Spot', field: 'spot', type: 'area' },
  { name: 'Smart: Convertible RIs', field: 'smart_convertible_reserved_instances', type: 'area' },
  { name: 'Smart: Compute SPs', field: 'smart_compute_savings_plans', type: 'area' },
  { name: 'Smart: EC2 Instance SPs', field: 'smart_ec2_instance_savings_plans', type: 'area' },
  { name: 'Flex', field: 'flex', type: 'area' },
  { name: 'Flex Boost', field: 'flex_boost', type: 'area' },
  { name: 'Base: Convertible RIs', field: 'base_convertible_reserved_instances', type: 'area' },
  { name: 'Base: Standard RIs', field: 'base_standard_reserved_instances', type: 'area' },
  { name: 'Base: EC2 Instance SPs', field: 'base_ec2_instance_savings_plans', type: 'area' },
  { name: 'Base: Compute SPs', field: 'base_compute_savings_plans', type: 'area' },
  { name: 'Unbilled', field: 'unbilled', type: 'area' },
  { name: 'Inherited: Convertible RIs', field: 'inherited_convertible_reserved_instances', type: 'area' },
  { name: 'Inherited: Standard RIs', field: 'inherited_standard_reserved_instances', type: 'area' },
  { name: 'Inherited: Compute SPs', field: 'inherited_compute_savings_plans', type: 'area' },
  { name: 'Inherited: EC2 Instance SPs', field: 'inherited_ec2_instance_savings_plans', type: 'area' },
  { name: SERIES_NAME_BASE_TARGET, field: 'base_spend_coverage_target', type: 'line' },
]);

// This is the order the items will be stacked in the chart (the first will be on the top)
const nonComputeSeriesDefs: NonComputeSeriesDef[] = [
  { name: 'On-Demand', field: 'on_demand', type: 'area' },
  { name: 'Smart', field: 'smart_reserved_coverage', type: 'area' },
  { name: 'Inherited', field: 'inherited_reserved_coverage', type: 'area' },
];

const months = computed(() => {
  const firstMonths = props.spendCoverageTrend.slice(0, -1);
  const lastMonth = props.spendCoverageTrend.slice(-1)[0] ?? {};
  // Don't include the last month if there aren't any fields other than month_start (i.e. no spend coverage data for the month)
  const includeLastMonth = Object.keys(lastMonth).some(key => key !== 'month_start');
  return includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
});

onMounted(() => {
  const seriesDefs = (isProductCompute.value ? computeSeriesDefs.value : nonComputeSeriesDefs).filter(isTruthy);

  const areaOptions = {
    fillOpacity: '0.5',
    dashStyle: 'solid',
    stacking: 'normal',
  };
  const lineOptions = {
    dashStyle: 'dash',
  };

  const mappedSeries = seriesDefs.map((s, index) => {
    var data = months.value.map(month => month[s.field] ?? null);
    return {
      label: s.name,
      color: getSeriesColor(s.field),
      // Use null as the default value to ensure that days without values are still shown on the x axis
      data,
      // Reverse the order of the legend so that the last item (bottom) is the leftmost
      legendOrder: seriesDefs.length - index,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
      marker: {
        enabled: chartUtilities.hasSingleDataPoint(data),
      },
      // Apply the options specific to the series type
      ...(s.type === 'area' ? areaOptions : lineOptions),
      // Allow the series to override any of the above properties
      ...s,
    };
  });

  series.value = mappedSeries
    // Don't include any series without a color (i.e. series belongs to another service or variant)
    .filter(s => !!s.color)
    // Remove any series without any data points
    .filter(s => s.data.some(d => !!d));

  // We don't re-capture historical stats for the all discounts view, force the chart to draw
  if (props.emptyYAxisMax && series.value.length === 0) {
    noData.value = true;
    series.value = [
      {
        type: 'area',
        data: xAxis.value.categories.map(() => null),
        legendOrder: 1,
        legendSelected: true,
      } as Series,
    ];
  }
});

const getSeriesColor = (field: keyof SpendCoverageTrend) => {
  const colors = isProductCompute.value ? seriesColors.compute[props.dashboardVariant!] ?? {} : seriesColors.nonCompute;

  return colors[field];
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    <div class="d-flex flex-row-reverse">
      <small>(normalized)</small>
    </div>
  </div>
</template>
