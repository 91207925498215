<script setup lang="ts">
import type { Resource } from '@console/components/users/ResourceSelector.vue';
import type { PropType } from 'vue';

import _ from 'lodash';
import { ref, computed, watch } from 'vue';

import { useAzureStore } from '@azure/state';
import Sentry from '@console/lib/sentry';
import { useVuexStore } from '@console/state/vuex/store';
import { Cloud, GranularRole, PermissionLevel } from '@shared/state/auth.store';
import { isDefined } from '@shared/utilities/typescript_helpers';

import ResourceSelector from '@console/components/users/ResourceSelector.vue';

interface SelectionItem {
  resourceId: string | null;
  permissionLevel: PermissionLevel | null;
}

const props = defineProps({
  selectedGranularRoles: {
    type: Array as PropType<GranularRole[]>,
    required: true,
  },
});

const emit = defineEmits(['update:selectedGranularRoles']);

const store = useVuexStore();
const azureStore = useAzureStore();
const selectedCustomerId = computed<string>(() => store.state.customer.selectedCompanyId);

const allowedPermissionLevels = [PermissionLevel.Editor, PermissionLevel.Viewer];

/* eslint-disable camelcase */
const awsResources = computed<Resource[]>(() => {
  // TODO remove the `as` typing when the store is typed properly
  const awsOrganizations = store.state.aws.awsOrganizations as {
    [id: string]: { id: string; friendly_name: string; status: string; management_aws_account_number: string };
  };
  return (
    _.sortBy(
      Object.values(awsOrganizations).map(o => {
        return {
          value: o.id,
          displayName: o.friendly_name,
          status: o.status,
          providerId: o.management_aws_account_number,
          cloud: Cloud.AWS,
        };
      }),
      ['status', 'displayName']
    ) ?? []
  );
});

const gcpResources = computed<Resource[]>(() => {
  // TODO remove the `as` typing when the store is typed properly
  const billingAccounts = store.state.gcp.billingAccounts as {
    [id: string]: { id: string; display_name: string; status: string; billing_account_id: string };
  };
  return (
    _.sortBy(
      Object.values(billingAccounts).map(a => {
        return {
          value: a.id,
          displayName: a.display_name,
          status: a.status,
          providerId: a.billing_account_id,
          cloud: Cloud.GCP,
        };
      }),
      ['status', 'displayName']
    ) ?? []
  );
});

const azureResources = computed<Resource[]>(() => {
  return (
    _.sortBy(
      Object.values(azureStore.billingScopes ?? {}).map(a => {
        return {
          value: a.id,
          displayName: a.display_name,
          status: a.status,
          providerId: a.billing_profile_id ?? a.billing_account_id,
          cloud: Cloud.AZURE,
        };
      }),
      ['status', 'displayName']
    ) ?? []
  );
});
/* eslint-enable camelcase */

const selectionItems = ref<SelectionItem[]>(
  props.selectedGranularRoles.map(role => {
    return {
      resourceId: role.resourceId,
      permissionLevel: role.permission,
    };
  })
);
const granularRoles = computed<GranularRole[]>(() => {
  return selectionItems.value
    .map(item => {
      if (item.resourceId && item.permissionLevel) {
        let cloud: Cloud;
        if (store.state.aws.awsOrganizations[item.resourceId]) {
          cloud = Cloud.AWS;
        } else if (store.state.gcp.billingAccounts[item.resourceId]) {
          cloud = Cloud.GCP;
        } else if (azureStore.billingScopes && azureStore.billingScopes[item.resourceId]) {
          cloud = Cloud.AZURE;
        } else {
          Sentry.captureMessage(
            'GranularRoleSelector.vue: resourceId not found in AWS, Google Cloud or Azure resources',
            {
              extra: { resourceId: item.resourceId, customerId: selectedCustomerId.value },
            }
          );
          return null;
        }
        return new GranularRole(selectedCustomerId.value, cloud, item.resourceId, item.permissionLevel);
      }
      return null;
    })
    .filter(isDefined);
});
watch(granularRoles, newGranularRoles => {
  emit('update:selectedGranularRoles', newGranularRoles);
});

// If there are no selection items yet, push an empty one to make the UX show a selection item
if (selectionItems.value.length === 0) {
  selectionItems.value.push({ resourceId: null, permissionLevel: null });
}

const allowAddAnother = computed<boolean>(() => {
  const lastItem = selectionItems.value[selectionItems.value.length - 1];
  return lastItem.resourceId !== null && lastItem.permissionLevel !== null;
});
const hasSelectedEditorPermission = computed<boolean>(() => {
  return selectionItems.value.some(item => item.permissionLevel === PermissionLevel.Editor);
});
const selectedResourceIdSet = computed<Set<string>>(() => {
  return new Set(selectionItems.value.map(item => item.resourceId).filter(isDefined));
});
const hasAllResources = computed<boolean>(() => {
  return (
    selectedResourceIdSet.value.size ===
    awsResources.value.length + gcpResources.value.length + azureResources.value.length
  );
});
</script>

<template>
  <div>
    <div class="align-items-center grid">
      <div class="label">Cloud Billing Entity</div>
      <div class="label">Role</div>
      <div></div>
      <template v-for="(selectionItem, index) in selectionItems" :key="`granular-role-select-${index}`">
        <ResourceSelector
          :selected-resource-id="selectionItem.resourceId"
          :selected-resource-ids="selectedResourceIdSet"
          :aws-resources="awsResources"
          :gcp-resources="gcpResources"
          :azure-resources="azureResources"
          class="resourceSelector selector"
          @update:selected-resource-id="newResourceId => (selectionItem.resourceId = newResourceId)"
        />
        <div class="permissionSelector selector">
          <select v-model="selectionItem.permissionLevel" class="form-control permissionSelect">
            <option value="" disabled selected>Select a role</option>
            <option v-for="permissionLevel in allowedPermissionLevels" :key="permissionLevel" :value="permissionLevel">
              <span>{{ permissionLevel }}</span>
              <span v-if="permissionLevel === PermissionLevel.Editor">*</span>
            </option>
          </select>
          <BaseIcon class="caret" name="angle-down" />
        </div>
        <div class="delete">
          <div v-if="index > 0" @click="selectionItems.splice(index, 1)">
            <BaseIcon name="times-circle" class="icon text-danger" />
          </div>
        </div>
      </template>
    </div>
    <button
      type="button"
      class="addAnother primary btn btn-link"
      :disabled="allowAddAnother === false || hasAllResources === true"
      @click="selectionItems.push({ resourceId: null, permissionLevel: null })"
    >
      + Add Another
    </button>
    <div v-if="hasSelectedEditorPermission" class="note mt-3 mb-2">
      *Note: Unlike the general Editor role, this does not include the ability to add new AWS Organizations, Azure
      Billing Scopes, or Google Cloud Billing Accounts
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selector {
  width: 375px;
  select {
    width: 200px;
  }
}
.permissionSelector {
  display: flex;
  align-items: center;
}
.permissionSelect {
  appearance: none;
}
.caret {
  margin-left: -15px;
}
.addAnother.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.addAnother {
  padding-left: 0;
}
.delete {
  margin-left: -5px;
  cursor: pointer;
}
.label {
  font-size: 0.95rem;
  font-weight: 500;
}
.note {
  font-size: 13px;
}
.grid {
  display: grid;
  grid-template-columns: 375px 200px 20px;
  row-gap: 10px;
  column-gap: 20px;
}
</style>
