<script setup lang="ts">
import type { AwsAdmOfferingCode, MonthStart, RegionResponse } from '@console/services/api.models';
import type {
  NonComputeSavingsDashboardResponse,
  NonComputeSpendCoverageTrend,
} from '@console/services/aws/savings.models';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { computed, ref, onMounted, watch } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';

import { useRegionStore } from '@aws/stores/region.store';
import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import DailySavingsChart from '@aws/components/adm/savings/DailySavingsChart.vue';
import DailySpendCoverageChart from '@aws/components/adm/savings/DailySpendCoverageChart.vue';
import EffectiveSavingsRate from '@aws/components/adm/savings/EffectiveSavingsRate.vue';
import NetSavingsTrendChart from '@aws/components/adm/savings/NetSavingsTrendChart.vue';
import PortfolioAllocation from '@aws/components/adm/savings/PortfolioAllocation.vue';
import RiUtilizationMetric from '@aws/components/adm/savings/RiUtilizationMetric.vue';
import SavingsBreakdown from '@aws/components/adm/savings/SavingsBreakdown.vue';
import SavingsCallout from '@aws/components/adm/savings/SavingsCallout.vue';
import SavingsMetric from '@aws/components/adm/savings/SavingsMetric.vue';
import SpendCoverageBreakdown from '@aws/components/adm/savings/SpendCoverageBreakdown.vue';
import SpendCoverageTrendChart from '@aws/components/adm/savings/SpendCoverageTrendChart.vue';
import RegionFilter from '@aws/components/filters/RegionFilter.vue';
import BuildingState from '@console/components/BuildingState.vue';
import DropdownFilter from '@console/components/filters/DropdownFilter.vue';
import Layout from '@console/Layout.vue';
import TimeframeSelector from '@shared/components/TimeframeSelector.vue';
import SlideFade from '@shared/components/transitions/SlideFade.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const router = useRouter();
onBeforeRouteUpdate(async (to, _, next) => {
  await load(Array.isArray(to.params.timeframe) ? to.params.timeframe[0] : to.params.timeframe);
  next();
});

const props = defineProps<{
  timeframe?: string;
  service: AwsAdmOfferingCode;
}>();

const savings = ref<NonComputeSavingsDashboardResponse>();
const selectedTimeframe = ref<MonthStart>();
const selectedRegion = ref<RegionResponse>();
const selectedMatchKey = ref<string>();
const savingsDashboardNotFound = ref(false);

useHead({
  title: 'Savings',
});

const store = useVuexStore();
const selectedCompany = computed(() => store.getters['customer/selectedCompany']);
const isDemo = computed(() => store.getters['customer/isDemo']);
const selectedOrganization = computed(() => store.getters['aws/selectedOrganization']);
const selectedOrganizationActiveAccounts = computed(() => store.getters['aws/selectedOrganizationActiveAccounts']);

const isLoading = computed(() => !selectedTimeframe.value && !savingsDashboardNotFound.value);
const savingsTimeframes = computed(() => _.get(savings.value, 'available_timeframes', []));
const serviceDisplayName = computed(() => AwsServiceHelpers.getDisplayName(props.service));

const regionStore = useRegionStore();
const regionOptions = computed(() => {
  const currentRegionSystemNames = [
    ...new Set<string>([...Object.keys(savings.value?.dashboard.daily_spend_coverage_by_region ?? {})]),
  ];

  const historicalRegionSystemNames = Object.keys(savings.value?.dashboard.spend_coverage_trend_by_region ?? {}).filter(
    k => !currentRegionSystemNames.includes(k)
  );
  const allRegionOptions = [
    {
      label: `Usage in ${selectedTimeframe.value?.friendly_name}`,
      values: regionStore
        .mapSystemNames([...currentRegionSystemNames])
        .sort((a, b) => a.display_name.localeCompare(b.display_name)),
    },
  ];
  if (historicalRegionSystemNames.length > 0) {
    allRegionOptions.push({
      label: `Usage Prior To ${selectedTimeframe.value?.friendly_name}`,
      values: regionStore
        .mapSystemNames([...historicalRegionSystemNames])
        .sort((a, b) => a.display_name.localeCompare(b.display_name)),
    });
  }

  return allRegionOptions;
});
watch(selectedRegion, () => {
  selectedMatchKey.value = undefined;
});

const matchKeyOptions = computed(() => {
  const region = selectedRegion.value?.system_name;

  let currentMatchKeys = [
    ...new Set<string>([...Object.keys(savings.value?.dashboard.daily_spend_coverage_by_match_key ?? {})]),
  ].sort();

  let historicalMatchKeys = Object.keys(savings.value?.dashboard.spend_coverage_trend_by_match_key ?? {})
    .filter(k => !currentMatchKeys.includes(k))
    .sort();

  if (region) {
    currentMatchKeys = currentMatchKeys.filter(mk => mk.startsWith(region));
    historicalMatchKeys = historicalMatchKeys.filter(mk => mk.startsWith(region));
  }
  const allMatchKeyOptions = [
    {
      label: `Usage in ${selectedTimeframe.value?.friendly_name}`,
      values: currentMatchKeys,
    },
  ];

  if (historicalMatchKeys.length > 0) {
    allMatchKeyOptions.push({
      label: `Usage Prior To ${selectedTimeframe.value?.friendly_name}`,
      values: historicalMatchKeys,
    });
  }

  return allMatchKeyOptions;
});

const useSmallerMetricText = false;

const hasDailySavings = computed(() => {
  const ds = savings.value?.dashboard.daily_savings;
  const savingsFlat = _.flatMap(ds, el => [el.inherited_savings, el.smart_savings]);
  return !_.every(savingsFlat, s => s === 0 || _.isUndefined(s));
});

onMounted(async () => {
  await load(props.timeframe || '');
});

const onChange = async (nextTimeframe: MonthStart) => {
  const timeframe = nextTimeframe.key;
  await router.push({
    name: router.currentRoute.value.name!,
    params: { timeframe },
    query: router.currentRoute.value.query,
  });
};

const load = async (timeframe: string) => {
  try {
    const awsOrganizationId = selectedOrganization.value.id;
    var regionPromise = regionStore.load();
    var resp = await customerService.getNonComputeSavings(awsOrganizationId, props.service, timeframe);
    savings.value = resp?.data as NonComputeSavingsDashboardResponse;
    if (timeframe) {
      const filter = { key: timeframe };
      selectedTimeframe.value = _.find(savings.value.available_timeframes, filter) as MonthStart;
    } else {
      selectedTimeframe.value = _.head(savings.value.available_timeframes);
    }
    await regionPromise;
  } catch (e) {
    await handleError(e, timeframe);
  }
};

const handleError = async (e: unknown, timeframe?: string) => {
  if (isAxiosError(e) && e.response?.status === 404) {
    if (!timeframe) {
      savingsDashboardNotFound.value = true;
    } else {
      await router.push({ name: '404' });
    }
  } else {
    throw e;
  }
};

const formatDate = (date: Date | string, format = 'MMMM D, YYYY') => {
  return dateFormat(dateUtc(date), format);
};

const showFilters = computed(() => regionOptions.value.length > 0);

const filterKeyPrefix = computed(() => {
  return selectedMatchKey.value ?? selectedRegion.value?.system_name ?? 'global';
});

const filteredSavings = computed(() => {
  const dailySpendCoverage = selectedMatchKey.value
    ? savings.value?.dashboard.daily_spend_coverage_by_match_key[selectedMatchKey.value]
    : selectedRegion.value
    ? savings.value?.dashboard.daily_spend_coverage_by_region[selectedRegion.value.system_name]
    : savings.value?.dashboard.daily_spend_coverage;

  let spendCoverageTrend = selectedMatchKey.value
    ? savings.value?.dashboard.spend_coverage_trend_by_match_key[selectedMatchKey.value]
    : selectedRegion.value
    ? savings.value?.dashboard.spend_coverage_trend_by_region[selectedRegion.value.system_name]
    : savings.value?.dashboard.spend_coverage_trend;

  const hasNoData = (sc: NonComputeSpendCoverageTrend) =>
    !sc.inherited_reserved_coverage && !sc.smart_reserved_coverage && !sc.on_demand;

  if (spendCoverageTrend?.every(hasNoData)) {
    spendCoverageTrend = [];
  }

  if (spendCoverageTrend?.length) {
    // add up to 12 months of data for spend coverage trend and sort final result
    const earliestDate = moment.utc(selectedTimeframe.value?.key).add(-12, 'M');
    for (let date = earliestDate; date <= moment.utc(selectedTimeframe.value?.key); date.add(1, 'M')) {
      if (!spendCoverageTrend?.find(sc => moment.utc(sc.month_start).isSame(date))) {
        spendCoverageTrend?.push({
          month_start: date.format(),
        });
      }
    }
    spendCoverageTrend?.sort((a, b) => moment.utc(a.month_start).diff(moment.utc(b.month_start)));
  }
  return {
    dailySpendCoverage: dailySpendCoverage ?? [],
    spendCoverageTrend: spendCoverageTrend ?? [],
  };
});
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="savingsDashboardNotFound" />
      <div v-else-if="savings && selectedTimeframe">
        <PageHeader wrap-utility>
          <h1>{{ serviceDisplayName }} Savings</h1>
          <template v-slot:utility>
            <TimeframeSelector
              :selected="selectedTimeframe"
              :timeframes="savingsTimeframes"
              :finalized="savings.dashboard.is_finalized"
              :data-through-date="savings.dashboard.data_through_date"
              @change="onChange"
            />
          </template>
        </PageHeader>
        <div v-if="savings.dashboard.subscription_start_date" class="row sectional">
          <div class="col">
            <BoxMessage type="info">
              <strong
                >{{ formatDate(savings.dashboard.subscription_start_date) }} was your first full day with the ProsperOps
                service enabled.</strong
              >
              This month is considered a transition month as the savings results reflect a blend of before and after
              ProsperOps management.
            </BoxMessage>
          </div>
        </div>
        <div v-if="savings.dashboard.savings_impacted_by_inherited" class="row sectional">
          <div class="col">
            <BoxMessage type="warning">
              <div v-if="savings.dashboard.is_finalized">
                <strong>Savings performance is impacted by unutilized inherited RIs. </strong>
                This will continue until these discount instruments expire.
              </div>
              <div v-else>
                <strong>Savings performance is impacted by unutilized inherited RIs.</strong>
                This will continue until these discount instruments expire or are otherwise disposed of. Please
                <router-link :to="{ name: 'help' }">contact us</router-link> for further guidance.
              </div>
            </BoxMessage>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <Panel v-if="savings">
              <SavingsCallout
                :savings="savings"
                :organization="selectedOrganization"
                :aws-accounts="selectedOrganizationActiveAccounts"
                :demo="isDemo"
                :service="service"
                :include-totals="false"
              />
            </Panel>
          </div>
        </div>
        <div class="row sectional summary">
          <div class="col-xl-auto effectiveSavingsRate">
            <PanelSection v-if="savings" header="Effective Savings Rate">
              <EffectiveSavingsRate
                :service="service"
                :data="savings.dashboard.effective_savings_rate"
                :pre-subscription-esr-days="savings.dashboard.pre_subscription_effective_savings_rate_days"
                :default-max="35"
              />
            </PanelSection>
          </div>
          <div class="col">
            <SavingsBreakdown :savings="savings" :demo="isDemo" :service="service" />
          </div>
        </div>
        <!-- Metrics -->
        <div class="row sectional">
          <div class="col-lg-12 col-xl-6">
            <SavingsMetric
              name="portfolio_actions"
              variant="success"
              :value="savings.dashboard.key_metrics.portfolio_actions"
              :use-smaller-text="useSmallerMetricText"
              :company="selectedCompany"
              tooltip-override="The number of purchase actions our service has taken to your commitment portfolio."
            />
          </div>
          <div class="col-lg-12 col-xl-6">
            <RiUtilizationMetric :dashboard="savings?.dashboard" :use-smaller-text="useSmallerMetricText" />
          </div>
        </div>
        <!-- Daily Savings -->
        <div v-if="hasDailySavings" class="row sectional">
          <div class="col">
            <PanelSection header="Daily Savings">
              <DailySavingsChart
                :daily-savings="savings.dashboard.daily_savings"
                :month-start="savings.month_start"
                :service="service"
              />
            </PanelSection>
          </div>
        </div>
        <!-- Net Savings Trend -->
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Net Savings Trend">
              <NetSavingsTrendChart :net-savings-trend="savings.dashboard.net_savings_trend" :service="service" />
            </PanelSection>
          </div>
        </div>
        <!-- Portfolio Allocation & Spend Coverage -->
        <div class="row sectional stack-sectional">
          <div class="col">
            <PanelSection header="Portfolio Allocation">
              <PortfolioAllocation
                v-if="savings.dashboard.portfolio_distribution"
                :portfolio-allocation="savings.dashboard.portfolio_distribution"
                :service="service"
              />
            </PanelSection>
          </div>
          <div class="col">
            <PanelSection header="Spend Coverage" class="spendCoverageBreakdown">
              <SpendCoverageBreakdown
                v-if="savings.dashboard.spend_coverage_summary"
                :spend-coverage="savings.dashboard.spend_coverage_summary"
                :finalized="savings.dashboard.is_finalized"
                :service="service"
              />
            </PanelSection>
          </div>
        </div>
        <hr />
        <div v-if="showFilters" class="row sectional">
          <div class="col d-flex flex-row flex-wrap gap-2">
            <!-- TODO: update to just v-model (remove :model-value) once we're no longer in  mode.
                  see: https://v3-migration.vuejs.org/breaking-changes/v-model -->
            <RegionFilter v-model:model-value="selectedRegion" :regions="regionOptions" />
            <DropdownFilter
              v-model:model-value="selectedMatchKey"
              label="Match Key"
              search-place-holder="Search Match Keys"
              all-keys-label="All Match Keys"
              :filter-values="matchKeyOptions"
            />
          </div>
        </div>
        <div class="spendCoverageContainer">
          <!-- Daily Spend Coverage -->
          <SlideFade>
            <div v-if="filteredSavings.dailySpendCoverage.length" class="row sectional">
              <div class="col">
                <PanelSection header="Daily Spend Coverage">
                  <div style="height: 450px">
                    <DailySpendCoverageChart
                      :key="filterKeyPrefix + '_daily_spend_coverage'"
                      :month-start="savings.month_start"
                      :daily-spend-coverage="filteredSavings.dailySpendCoverage"
                      :service="service"
                    />
                  </div>
                </PanelSection>
              </div>
            </div>
          </SlideFade>
          <!-- Spend Coverage Trend -->
          <SlideFade>
            <div v-if="filteredSavings.spendCoverageTrend.length" class="row sectional">
              <div class="col">
                <PanelSection header="Spend Coverage Trend">
                  <SpendCoverageTrendChart
                    :key="filterKeyPrefix + '_spend_coverage_trend'"
                    :spend-coverage-trend="filteredSavings.spendCoverageTrend"
                    :service="service"
                  />
                </PanelSection>
              </div>
            </div>
          </SlideFade>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.row.summary {
  display: flex;

  > div:first-child {
    flex-basis: auto;

    @media (min-width: 1225px) {
      flex-basis: 380px;
    }
  }
}

.metricPanel {
  min-width: 160px;
}

.organizationCountWidget {
  margin-top: 30px;
}

.effectiveSavingsRate > :deep(section) {
  padding-top: 0;
}

.effectiveSavingsRate {
  max-height: 560px;
}

.spendCoverageBreakdown {
  height: auto; // Don't expand the panel to match the Portfolio Allocation panel's height
}

.spendCoverageContainer {
  // prevent scrolling up when switching between a filter with only daily to a filter with only trend
  min-height: 552px;
}

@include media-breakpoint-only(xl) {
  .metricPanelLargeNumbers {
    padding: 0;
    margin: 0;
  }

  .colPadding {
    padding: 0;
  }
}
</style>
