import type {
  AxisLabelsFormatterCallbackFunction,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';

import Big from 'big.js';
import he from 'he';
import _ from 'lodash';
import moment from 'moment';

import NumberHelpers from '@shared/utilities/number_helpers';
import { isDefined } from '@shared/utilities/typescript_helpers';

export { dateFormat } from 'highcharts';

export const datetimeZoomLabelFormatter: AxisLabelsFormatterCallbackFunction = function () {
  const overrides = { day: 'MMM DD, YYYY', week: 'MMM DD, YYYY' };
  const unit = _.get(this, 'tickPositionInfo.unitName', 'unknown');
  const format = _.get(overrides, unit, 'MMM YYYY');
  return moment.utc(this.value).format(format);
};

const plotLine = (date: Date | moment.Moment) => {
  const name = date.toString();
  const value = +date;
  return { name, value, color: '#e6e6e6' };
};

export function monthlyPlotLines(startDate: Date, endDate: Date) {
  const positions = [];
  const current = moment.utc(startDate).startOf('month');
  const end = moment.utc(endDate);
  while (current.isBefore(end)) {
    positions.push(plotLine(current));
    current.add(1, 'month');
  }
  positions.push(plotLine(end));
  return positions;
}

export function axisMax(
  defaultMax: number,
  absoluteMax: number,
  increment: number,
  value?: number | (number | null | undefined)[] | null
) {
  if (typeof value === 'number') {
    value = [value];
  }

  if (Array.isArray(value)) {
    const filteredValues = value.filter(isDefined);
    if (filteredValues.length === 0) {
      return defaultMax;
    }

    const maxValue = Math.max(...filteredValues);
    if (maxValue >= defaultMax) {
      const nextMultiple = Math.floor(maxValue / increment) * increment + increment;
      return Math.min(nextMultiple, absoluteMax);
    }
  }

  return defaultMax;
}

export const esrGaugeMax = (esr?: number | null, targetMax?: number | null) => axisMax(targetMax ?? 50, 100, 5, esr);

// prevent x-axis labels from wrapping or overlapping when zoomed
// see: https://api.highcharts.com/highcharts/xAxis.units
export const units: XAxisOptions['units'] = [
  ['millisecond', [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]],
  // Allow only 1 label per 5 days
  ['day', [5]],
  // Allow only 1 label per 3 weeks
  ['week', [3]],
  ['month', [1, 2, 3, 4, 6]],
  ['year', null],
];

// Returns the number of decimal places needed to display reasonable values
// on a chart with the given max value. This displays 0 digits for values
// >= 10, and 2 digits for smaller values.
export function decimalPlacesNeededForChartMax(maxValue?: number | null) {
  const max = maxValue ?? 0;
  return max === 0 || max >= 10 ? 0 : 2;
}

export const percentFormat = '<span style="font-size:12px">{value}%</span>';

export function getCurrencyFormat(maxValue?: number | null) {
  const decimalPlaces = decimalPlacesNeededForChartMax(maxValue);
  return '<span style="font-size:12px">${value:,.' + decimalPlaces + 'f}</span>';
}

export const getMonthYAxis = (maxValue: number): Partial<YAxisOptions> => {
  let decimalPlaces: number;
  if (maxValue >= 4) {
    decimalPlaces = 0;
  } else if (maxValue >= 0.3) {
    decimalPlaces = 1;
  } else {
    decimalPlaces = 2;
  }

  return {
    min: 0,
    minTickInterval: getMinTickInterval(decimalPlaces),
    title: {
      text: 'Months',
      margin: 20,
    },
    labels: {
      format: '<span style="font-size:12px">{value:,.' + decimalPlaces + 'f}</span>',
    },
  };
};

const getMinTickInterval = (decimalPlaces: number) => 1 * 10 ** -decimalPlaces;

interface TooltipWithTotalFormatterOptions {
  totalLabel: string;
  valueFormatter: (value: number | null | undefined) => string;

  /**
   * Customer formatter for the x-axis label at the top of the tooltip. If not provided, the X value is shown as-is.
   * This is useful for providing date formatting for datetime axes.
   */
  xLabelFormatter?: (x: number | string | undefined) => string;

  /**
   * List of series names that should be excluded from the total. These series will be placed at the top of the
   * tooltip, in the order they are defined in the chart, before the series that are included in the total.
   */
  seriesExcludedFromTotal?: string[];
}

export const defaultTooltip = {
  enabled: true,
  borderRadius: 0,
  borderColor: '#adb5bd',
  shared: true,
  shadow: false,
  useHTML: true,
};

export function tooltipWithTotal(options: TooltipWithTotalFormatterOptions): TooltipOptions {
  return {
    enabled: true,
    borderRadius: 0,
    borderColor: '#adb5bd',
    shared: true,
    shadow: false,
    useHTML: true,
    formatter: tooltipWithTotalFormatter(options),
  };
}

export function getTooltipRows(
  points: Array<TooltipFormatterContextObject>,
  valueFormatter: (value: number | null | undefined) => string
) {
  return points.map(point => ({
    seriesName: point.series.name,
    value: point.y,
    html: encodedTooltipRow(point.series.name, point.y, valueFormatter, point.color as string),
  }));
}

function tooltipWithTotalFormatter(options: TooltipWithTotalFormatterOptions): TooltipFormatterCallbackFunction {
  return function () {
    const points = this.points ?? [];
    const rows = getTooltipRows(points, options.valueFormatter);

    const excludedSeries = options.seriesExcludedFromTotal ?? [];
    const excludedRows = rows.filter(r => excludedSeries.includes(r.seriesName));
    const includedRows = rows.filter(r => !excludedSeries.includes(r.seriesName));

    let totalHtml = '';
    if (includedRows.length >= 2) {
      const value = Number(includedRows.reduce((sum, p) => sum.add(p.value ?? 0), Big(0)));

      totalHtml = `
      <div style="padding-top: 2px">
        ${encodedTooltipRow(options.totalLabel, value, options.valueFormatter)}
      </div>`;
    }

    const excludedRowsHtml = excludedRows.map(r => r.html).join('');
    const includedRowsHtml = includedRows.map(r => r.html).join('');

    // Highchart's typing for x is number, even though it can be a string, so cast it to fix
    const xLabel = options.xLabelFormatter?.(this.x) ?? ((this.x as unknown) as string);
    return `
      <div>
        <div style="font-size: 12px;">
          <strong>${he.encode(xLabel)}</strong>
        </div>
        ${excludedRowsHtml /* excluded rows are shown first */}
        ${includedRowsHtml}
        ${totalHtml}
      </div>
    `;
  };
}

export function encodedTooltipRow(
  label: string,
  value: number | null | undefined,
  valueFormatter?: (value: number | null | undefined) => string,
  color?: string,
  percentage?: number
) {
  let styles = ['display: inline-block', 'height: 9px', 'width: 9px'];

  if (color) {
    styles = [...styles, 'border-radius: 50%', `background-color: ${color}`];
  }
  let valueString;
  if (valueFormatter) {
    valueString = valueFormatter(value);
  } else {
    valueString = value?.toString() ?? '';
  }

  let percentageHtml = '';
  if (percentage !== undefined) {
    percentageHtml = `
      <span>
        (${NumberHelpers.formatNumber(percentage, 1)}%)
      </span>
    `;
  }
  return `
      <div>
        <div style="${he.encode(styles.join(';'))}"></div>
        <span>
          ${he.encode(label)}:
        </span>
        <strong>
          ${he.encode(valueString)}
        </strong>
        ${percentageHtml}
      </div>
    `;
}

export const hasSingleDataPoint = (data: (number | null | undefined)[]) => data.filter(isDefined).length === 1;
