<script lang="ts" setup>
import type { OrganizationSettingsSummary } from '@console/services/api.models';

import { computed } from 'vue';

import * as DateHelpers from '@shared/utilities/date_helpers';

import BaseTarget from './BaseTarget.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const props = defineProps<{
  settings: OrganizationSettingsSummary;
}>();

const baseTargetAutoIncreasePercentage = computed(() => props.settings?.base_target_auto_increase_percentage ?? 40);
const enabledBaseTargetAutoIncrease = computed(() => !!props.settings?.base_target_auto_increase_percentage);
const enabledEDPExpirationAlignment = computed(() => !!props.settings?.base_edp_expiration_alignment_days);

const edpExpirationAlignmentDays = computed(() => {
  const expirationDays = props.settings?.base_edp_expiration_alignment_days ?? 90;
  const unit = expirationDays === 1 ? 'day' : 'days';
  return `${expirationDays} ${unit}`;
});

const edpExpirationDate = computed(() => {
  const expirationDate = props.settings?.enterprise_discount_program_expiration_date;
  return expirationDate ? ` of ${DateHelpers.formatDateDateOnly(expirationDate)}` : '';
});
</script>

<template>
  <SettingsPanel header="Base">
    <template v-slot:utility>
      <LastUpdated :value="settings.base_last_updated" />
    </template>
    <SettingRow class="settingRow" data-test-id="base-target">
      <template v-slot:label> Base Target </template>
      <template v-slot:description>
        Desired amount of Compute Savings Plan spend coverage to maintain (in on-demand equivalent dollars for a 30 day
        month)
      </template>
      <template v-slot:value>
        <BaseTarget :settings="settings" />
      </template>
    </SettingRow>
    <SettingRow class="settingRow">
      <template v-slot:label> Base Prepayment Preference </template>
      <template v-slot:description> Upfront prepayment preference for newly deployed Compute Savings Plans </template>
      <template v-slot:value>
        <span v-if="settings.base_pre_payment_preference" class="font-weight-bold">
          {{ settings.base_pre_payment_preference }}
        </span>
        <span v-else> - </span>
      </template>
    </SettingRow>
    <div class="mt-4">
      <span class="text-uppercase font-weight-bold">Advanced</span>
    </div>
    <SettingRow class="settingRow">
      <template v-slot:label>EDP Expiration Alignment</template>
      <template v-slot:description>
        Do not make new Compute Savings Plan purchases if they extend commitment more than
        {{ edpExpirationAlignmentDays }} past your EDP expiration date{{ edpExpirationDate }}, and instead cover with
        term-optimized Flex
      </template>
      <template v-slot:value>
        <StatusPill :enabled="enabledEDPExpirationAlignment" variant="primary">
          <span v-if="enabledEDPExpirationAlignment">Enabled</span>
          <span v-else>Disabled</span>
        </StatusPill>
      </template>
    </SettingRow>
    <SettingRow v-if="!settings.adm_compute_sp_ladder_enabled" class="settingRow">
      <template v-slot:label>Base Target Auto Increase</template>
      <template v-slot:description>
        When the amount of compute usage that can
        <strong>only</strong> be covered by Compute Savings Plans increases above the Base Target, automatically
        increase the Base Target to cover as long as the new target amount is less than
        {{ baseTargetAutoIncreasePercentage }}% of compute usage
      </template>
      <template v-slot:value>
        <StatusPill :enabled="enabledBaseTargetAutoIncrease" variant="primary">
          <span v-if="enabledBaseTargetAutoIncrease">Enabled</span>
          <span v-else>Disabled</span>
        </StatusPill>
      </template>
    </SettingRow>
  </SettingsPanel>
</template>
