<script setup lang="ts">
import type { BillingScopeResponse } from '@azure/services/billingScope';
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';
import type { Tenant } from '@azure/services/subscriptions';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import _ from 'lodash';
import { onMounted, ref } from 'vue';

import { get } from '@azure/services/servicePrincipal';
import { getSubscriptionsGroupedByTenant } from '@azure/services/subscriptions';
import { useAzureStore } from '@azure/state';

import AzureTenantFilterList from '@azure/components/tenants/AzureTenantFilterList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import SpinnerLogo from '@shared/design/spinners/SpinnerLogo.vue';

const azureState = useAzureStore();
const loading = ref(true);
const gatheringDetails = ref(true);
const tenantActionNeeded = ref(false);
const tenants = ref<Tenant[]>([]);
const billingScope = ref<BillingScopeResponse | undefined>(undefined);
const servicePrincipal = ref<CreateServicePrincipalResponse | undefined>(undefined);

const props = defineProps<{
  prev: () => void;
  next: () => void;
}>();

onMounted(async () => {
  loading.value = true;
  const billingScopeId = azureState.getActiveBillingScopeId();
  if (billingScopeId) {
    const result = await pollCollectingInformation(billingScopeId, 1000);
    gatheringDetails.value = !result;
    if (result) {
      billingScope.value = azureState.getBillingScopeById(billingScopeId);
      const servicePrincipalId = billingScope.value?.service_principal_id;
      if (servicePrincipalId) {
        servicePrincipal.value = await get(servicePrincipalId);
      }
      tenants.value = await getSubscriptionsGroupedByTenant(billingScopeId);
      if (_.some(tenants.value, { validated: false })) {
        tenantActionNeeded.value = true;
      } else {
        props.next();
      }
    }
  }
  loading.value = false;
});

const validateTenant = async (tenant: Tenant) => {
  tenant.validated = true;

  // Set all subscriptions to active - this will be an API call in the future
  _.each(tenant.subscriptions, s => {
    s.status = 'Active';
  });

  // If there are no more tenants that need validation, move to the next step
  if (!_.some(tenants.value, { validated: false })) {
    props.next();
  }
};

const validateAll = () => {
  _.each(tenants.value, t => {
    validateTenant(t);
  });
};

// This is a stub to simulate polling the API to see if CaptureSubscriptions is completed
const pollCollectingInformation = async (billingScopeId: string, interval: number): Promise<boolean> => {
  let count = 0;
  while (count < 2 && billingScopeId.length > 0) {
    count++;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(false);
      }, interval);
    });
  }
  return await new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, interval);
  });
};
</script>

<template>
  <div class="gatheringDetails">
    <div v-if="gatheringDetails" class="row">
      <div class="col">
        <h2>Gathering additional details about your Azure Environment</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            <div class="spinner">
              <SpinnerLogo />
              <div class="text">
                Gathering additional information about your Azure Environment...
                <br />
                <small>This may take a few minutes.</small>
              </div>
            </div>
          </BoxMessage>
        </div>
      </div>
    </div>
    <div v-if="tenantActionNeeded">
      <AzureTenantFilterList :validate-tenant="validateTenant" :validate-all="validateAll" :tenants="tenants" />
    </div>
  </div>
</template>

<style lang="scss">
.onboardingCodeSnippet .codePreview pre {
  height: auto;
}
div.validationStepperItemWrapper {
  cursor: pointer;
}

.spinner {
  padding-bottom: 3rem;
  font-weight: 200;
}
</style>
