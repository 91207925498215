<script lang="ts" setup>
import type { AzureTenantStatus } from '@azure/services/subscriptions';

import { computed } from 'vue';

import TenantHelpers from '@azure/components/tenants/tenant_helpers';

import Pill from '@shared/design/Pill.vue';

const props = defineProps<{
  status: AzureTenantStatus;
}>();

const variant = computed(() => TenantHelpers.getStatusVariant(props.status));
const label = computed(() => TenantHelpers.getStatusLabel(props.status));
</script>

<template>
  <Pill class="statusWrapper p-2 mr-4" :variant="variant">
    <span class="d-none d-sm-inline w-auto" :title="label">{{ label }}</span>
  </Pill>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.statusWrapper {
  padding-bottom: 0;
  font-size: 0.75rem;
  text-align: center;
  word-wrap: normal;
  white-space: normal;

  @include media-breakpoint-up(sm) {
    display: block;
    width: 110px;
  }
}
</style>
