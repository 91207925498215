<script setup lang="ts">
// refer to src\aws\views\compute\Savings.vue
import type { ComputeSavingsDashboardResponse } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureFlag } from '@shared/state/feature.store';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import DailySavingsChart from '@azure/components/savings/DailySavingsChart.vue';
import DailySpendCoverageChart from '@azure/components/savings/DailySpendCoverageChart.vue';
import EffectiveSavingsRate from '@azure/components/savings/EffectiveSavingsRate.vue';
import NetSavingsTrendChart from '@azure/components/savings/NetSavingsTrendChart.vue';
import PortfolioActions from '@azure/components/savings/PortfolioActions.vue';
import ReservationUtilization from '@azure/components/savings/ReservationUtilization.vue';
import SavingsBreakdown from '@azure/components/savings/SavingsBreakdown.vue';
import SavingsCallout from '@azure/components/savings/SavingsCallout.vue';
import SavingsPlanUtilization from '@azure/components/savings/SavingsPlanUtilization.vue';
import SpendCoverageBreakdown from '@azure/components/savings/SpendCoverageBreakdown.vue';
import SpendCoverageTrendChart from '@azure/components/savings/SpendCoverageTrendChart.vue';
import TimeframeSelector from '@shared/components/TimeframeSelector.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const router = useRouter();
const store = useVuexStore();

useHead({
  title: 'Compute Savings',
});

const props = defineProps<{
  savings: ComputeSavingsDashboardResponse;
  selectedTimeframe: MonthStart;
}>();

const showSpendCoverageWidget = useFeatureFlag('azureSpendCoverageWidget');
const showDailySpendCoverageWidget = useFeatureFlag('azureDailySpendCoverageWidget');
const showNetSavingsTrendWidget = useFeatureFlag('azureNetSavingsTrendWidget');

const showSpendCoverageTrendWidget = useFeatureFlag('azureSpendCoverageTrendWidget');
const savings = computed(() => props.savings);
const dashboard = computed(() => savings.value.dashboard);
const customerVisible = computed(() => savings.value.customer_visible ?? false);
const savingsTimeframes = computed(() => props.savings.available_timeframes);
const hasPortfolioActions = computed(() => props.savings.dashboard.key_metrics?.action_count > 0);
const hasSavingsPlans = computed(
  () =>
    props.savings.dashboard.key_metrics?.savings_plan_utilization_percentage !== null &&
    props.savings.dashboard.key_metrics?.savings_plan_utilization_percentage !== undefined
);
const hasReservations = computed(
  () =>
    props.savings.dashboard.key_metrics?.reservation_utilization_percentage !== null &&
    props.savings.dashboard.key_metrics?.reservation_utilization_percentage !== undefined
);
const isDemo = computed(() => store.getters['customer/isDemo']);
const onChange = async (nextTimeframe: MonthStart) => {
  const timeframe = nextTimeframe.key;
  await router.push({ name: 'azure_compute_savings', params: { timeframe }, query: router.currentRoute.value.query });
};
const hasDailySavings = computed(() => {
  const ds = props.savings.dashboard.daily_savings;
  return ds.some(d => Object.entries(d).some(([field, value]) => field !== 'day' && !!value));
});
const hasSpendCoverage = computed(() => {
  return !!props.savings.dashboard.spend_coverage;
});
const hasDailySpendCoverage = computed(() => {
  const ds = props.savings.dashboard.daily_savings;
  return ds.some(d => Object.entries(d).some(([field, value]) => field !== 'day' && !!value));
});

const hasTrendData = computed(() => {
  return props.savings.dashboard.net_savings_trend?.length > 0;
});
const hasSpendCoverageTrendData = computed(() => {
  return props.savings.dashboard.spend_coverage_trend_months?.length > 0;
});

const formatDate = (date: string, format: string = 'MMMM D, YYYY') => dateFormat(dateUtc(date), format);
</script>

<template>
  <div>
    <PageHeader wrap-utility>
      <h1>Compute Savings</h1>
      <template v-slot:utility>
        <TimeframeSelector
          :selected="selectedTimeframe"
          :timeframes="savingsTimeframes"
          :finalized="dashboard.is_finalized"
          :data-through-date="dashboard.data_through_date"
          @change="onChange"
        />
      </template>
    </PageHeader>
    <div v-if="dashboard.subscription_start_date" class="row sectional">
      <div class="col">
        <BoxMessage type="info">
          <strong
            >{{ formatDate(dashboard.subscription_start_date) }} was your first full day with the ProsperOps service
            enabled.</strong
          >
          This month is considered a transition month as the savings results reflect a blend of before and after
          ProsperOps management.
        </BoxMessage>
      </div>
    </div>
    <div v-if="!customerVisible" class="row sectional">
      <div class="col">
        <BoxMessage type="error">
          <div>This month is not currently customer visible.</div>
        </BoxMessage>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <Panel>
          <SavingsCallout
            :total-savings="dashboard?.savings_summary.total_savings"
            :month="dashboard.data_through_date"
            :is-finalized="dashboard.is_finalized"
          />
        </Panel>
      </div>
    </div>
  </div>
  <div class="row sectional">
    <div class="col-xl-auto effectiveSavingsRate">
      <EffectiveSavingsRate :data="dashboard?.effective_savings_rate"></EffectiveSavingsRate>
    </div>
    <div class="col">
      <SavingsBreakdown :savings="savings" :selected-timeframe="selectedTimeframe" :demo="isDemo" service="compute" />
    </div>
  </div>
  <div class="row sectional">
    <div v-if="hasPortfolioActions" class="col-lg">
      <PortfolioActions :count="dashboard.key_metrics.action_count" />
    </div>
    <div v-if="hasReservations" class="col-lg">
      <ReservationUtilization
        :utilization="dashboard.key_metrics.reservation_utilization_percentage"
        :has-reservations="hasReservations"
      />
    </div>
    <div v-if="hasSavingsPlans" class="col-lg">
      <SavingsPlanUtilization
        :utilization="dashboard.key_metrics.savings_plan_utilization_percentage"
        :has-savings-plans="hasSavingsPlans"
      />
    </div>
  </div>

  <!-- Daily Savings -->
  <div v-if="hasDailySavings" class="row sectional">
    <div class="col">
      <PanelSection header="Daily Savings">
        <DailySavingsChart :daily-savings="dashboard.daily_savings" :month-start="selectedTimeframe" />
      </PanelSection>
    </div>
  </div>
  <div v-if="hasTrendData && showNetSavingsTrendWidget" class="row sectional">
    <div class="col">
      <NetSavingsTrendChart :net-savings-trend="dashboard.net_savings_trend" />
    </div>
  </div>
  <!-- Portfolio evaluations and Spend Coverage-->
  <div v-if="showSpendCoverageWidget && hasSpendCoverage" class="row sectional">
    <div class="col-lg">
      <!--leaving it empty for Portfolio eval-->
    </div>
    <div class="col-lg">
      <SpendCoverageBreakdown :spend-coverage="dashboard.spend_coverage" :finalized="dashboard.is_finalized" />
    </div>
  </div>

  <div v-if="showDailySpendCoverageWidget && hasDailySpendCoverage" class="row sectional">
    <div class="col">
      <DailySpendCoverageChart
        :daily-spend-coverage="dashboard.daily_spend_coverages"
        :month-start="selectedTimeframe"
      />
    </div>
  </div>

  <div v-if="showSpendCoverageTrendWidget && hasSpendCoverageTrendData" class="row sectional">
    <div class="col">
      <SpendCoverageTrendChart
        :spend-coverage-trend="dashboard.spend_coverage_trend_months"
        :month-start="selectedTimeframe"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.row.summary {
  display: flex;

  > div:first-child {
    flex-basis: auto;

    @include media-breakpoint-up(xl) {
      flex-basis: 380px;
    }
  }
}

.metricPanel {
  min-width: 160px;
}

.organizationCountWidget {
  margin-top: 30px;
}

.effectiveSavingsRate > :deep(section) {
  padding-top: 0;
}

.effectiveSavingsRate {
  flex-basis: auto;
  max-height: 560px;

  @include media-breakpoint-up(xl) {
    flex-basis: 380px;
  }
}

.spendCoverageBreakdown {
  height: auto; // Don't expand the panel to match the Portfolio Allocation panel's height
}

@include media-breakpoint-only(xl) {
  .metricPanelLargeNumbers {
    padding: 0;
    margin: 0;
  }

  .metricPanelSmallNumbers {
    padding: 0;
    margin-right: 4%;
    margin-left: 4%;
  }

  .colPadding {
    padding: 0;
  }
}
</style>
